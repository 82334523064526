.employee_list_component {
  max-width: 940px;
  width: 100%;
  margin: auto;
  h2 {
    text-align: center;
    font-family: 'PT Serif';
    font-weight: 700;
    font-size: 45px;
    color: #28292D;
    letter-spacing: 0;
    line-height: 51px;
    margin-bottom: 12px;
  }
  .employee_list_component_body {
    text-align: center;
    max-width: 750px;
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
  }
  .employee_list_items {
    .field-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .field-item {
        .employee_detail_teaser {
          margin: 0 25px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          justify-content: space-between;
          max-width: 400px;
          padding: 0 25px;
          text-align: center;
          .employee_detail_photo {            
            img {
              max-width: 180px;
              width: 100%;
              height: auto;
            }
          }
          .employee_detail_job_title {
            font-family: 'PT Serif';
            font-size: 30px;
            color: #28292D;
            letter-spacing: 0;
            line-height: 36px;
            margin-bottom: 12px;
            @media screen and (max-width: 1199px) {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}
body.page-jobs,
body.page-jobs-archive {
  background-color: #fff;

  #main-wrapper {
    max-width: 1140px;
    width: 100%;
    margin: auto;
    .radix-layouts-header {
      padding: 40px 15px;
    }
    .jobheader {
      .row {
        .col-md-8 {
          h1 {
            margin-top: 0;
          }
        }
        .col-md-4 {
          text-align: right;
          .btn {
            margin-bottom: 20px;
            &:hover {
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .col-md-4.radix-layouts-sidebar {
      background-color: $brand-neutral-light;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
      .panel-pane {
        padding: 0;
      }
      .views-widget-filter-field_job_job_title {
        label {
          padding-top: 0;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: normal;
        }    
      }
      .views-widget-filter-field_job_sector {
        border-top: 1px solid $brand-neutral-light-mid;
        padding-top: 15px;
        margin-top: 15px;
        label {
          padding-top: 0;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
      .views-widget-filter-search_api_views_fulltext {
        border-top: 1px solid $brand-neutral-light-mid;
        padding-top: 15px;
        margin-top: 15px;
        label {
          padding-top: 0;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
      .pane-facetapi {
        border-top: 1px solid $brand-neutral-light-mid;
        padding-top: 15px;
        margin-top: 15px;
        h4 {
          padding-top: 0;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
      .job-board-newsletter-signup {
        border-top: 1px solid $brand-neutral-light-mid;
        padding-top: 15px;
        margin-top: 15px;
      }

          
    }

    .col-md-8.radix-layouts-content {
      .view-jobs-search-index {
        .view-content {
          > .views-row {
            margin-bottom: 12px;
            .border {        
              border: 2px solid $brand-secondary-accent;
              border-radius: 5px;
              padding: 10px;
              background-color: $body-bg-white;
              position: relative;
      
              -webkit-box-shadow: 0px 0px 15px 10px rgba(242,241,240,1);
              -moz-box-shadow: 0px 0px 15px 10px rgba(242,241,240,1);
              box-shadow: 0px 0px 15px 10px rgba(242,241,240,1);
              
      
              &.featuredjob-1 {
                border: 2px solid $brand-secondary-accent;
                
                .flag {
                  background-color: $brand-secondary-accent;
                  color: #fff;
                  padding: 5px 10px;
                  position: absolute;
                  right: 15px;
                  top: 0px;
                  text-transform: uppercase;
                  font-size: 12px;
                  font-family: "proxima-nova";
                  font-weight: bold;
                }
                .first {
                  .logo {
                    .logo_logo {
                      display: block;
                    }
                    .logo_title {
                      display: none;
                    }
                  }
                }
              }
              &.featuredjob-0 {
                border: 2px solid $body-bg-white;

                .flag {
                  display: none;
                }
                .first {
                  .logo {
                    .logo_logo {
                      display: none;
                    }
                    .logo_title {
                      display: block;
                    }
                  }
                }
              }

              .logo_title {
                .noimage {
                  font-family: "proxima-nova";
                  font-size: 24px;
                  color: #fff;
                  text-transform: uppercase;
                  width: 56px;
                  height: 56px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  background-color: $brand-primary-accent;
                }
              }
      
              .flex {
                justify-content: flex-start;
                @media screen and (min-width: 600px) {
                  flex-direction: row;
                }
                .first  {
                  flex-wrap: nowrap;
                  width: 100%;
                  flex-direction: row;
                  padding-top: 20px;
                  @media screen and (min-width: 600px) {
                    width: 50%;
                    padding-top: unset;
                  }
                  @media screen and (min-width: 769px) {
                    max-width: 50%;
                    padding-right: 10px;
                  }
                  .logo {
                    max-width: 56px;
                    width: 100%;
                    height: auto;
                    margin-right: 10px;
                    img {
                      max-width: 56px;
                      width: 100%;
                      height: auto;
                    }
                  }
                  .details {
                    .jobtitle {
                      margin-bottom: 5px;
                      a {            
                        font-size: 18px;
                        font-weight: 600;
                      }
                    }
                    .small {
                      line-height: 20px;
                    }
                  }
                }
                .second {
                  flex-direction: column;
                  justify-content: space-around;
                  align-items: center;
                  align-content: center;
                  width: 100%;
                  display: none;
                  @media screen and (min-width: 600px) {
                    width: 25%;
                    display: flex;
                  }
                  @media screen and (min-width: 769px) {
                    max-width: 30%;
                    padding: 0 10px;
                  }
                  .sector {
                    padding-left: 25px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/sector_checkmark.svg');
                  }
                }
                .third  {
                  flex-direction: column;
                  justify-content: space-around;
                  align-items: center;
                  align-content: center;
                  width: 100%;
                  display: none;
                  @media screen and (min-width: 600px) {
                    width: 25%;
                    display: flex;
                  }
                  @media screen and (min-width: 769px) {
                    max-width: 20%;
                    padding: 0px 0px 0px 10px;
                  }
                  .joblevel {
                    padding-left: 25px;
                    background-repeat: no-repeat;
                    background-position: left center;             
                  }
                  .level-label {
                    padding-left: 25px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    &.level-48 { background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_senior.svg'); }
                    &.level-49 { background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_mid.svg'); }
                    &.level-50 { background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_entry.svg'); }
                    &.level-73 { background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_mid.svg'); }
                  }
                  
                }
              }
            }
          }
        }
      }
    }
  }

  .view-jobs-page-article-view {
    .view-content {
      .container-fluid {
        max-width: 1140px;
        padding-top: 40px;
        padding-bottom: 40px;
        .rows {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;
          @media screen and (min-width: 1024px) {
            flex-direction: row;
          }
          .views-row {
            width: 100%;
            margin-bottom: 20px;
            @media screen and (min-width: 1024px) {
              max-width: 50%;
              margin-bottom: 0;
            }
            .views-field {
              .field-content {
                .flex {
                  flex-direction: row;
                  @media screen and (max-width: 600px) {
                    flex-direction: column;
                  }
                  .first {
                    max-width: 290px;
                    width: 100%;
                    padding-right: 25px;
                    @media screen and (max-width: 600px) {
                      max-width: 100%;
                      padding-right: 0;
                    }
                    img {
                      max-width: 265px;
                      width: 100%;
                      height: auto;
                      @media screen and (max-width: 600px) {
                        max-width: 100%;
                      }
                    }
                  }
                  .second {
                    max-width: 40%;
                    width: 100%;
                    @media screen and (max-width: 600px) {
                      max-width: 100%;
                      padding-top: 10px;
                    }
                    a {
                      font-family: "PT Serif";
                      font-size: 21px;
                      color: $brand-neutral-dark;
                      text-decoration: none;
                      &:hover {
                        text-decoration: underline;
                      }
                    }
                    .readmore {
                      margin: 20px 0;
                      a {
                        border: 2px solid $brand-primary;
                        background-color: #fff;
                        border-radius: 5px;
                        padding: 10px 25px;
                        font-family: "proxima-nova";
                        font-size: 18px;
                        font-weight: bold;
                        &:hover {
                          border: 2px solid $brand-primary-hover;
                          background-color: $brand-primary-hover;;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } 
  }

  

}
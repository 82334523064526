// Styles for Views
// --------------------------------------------------
.flexslider {
    border: 0;
    box-shadow: none;
}

.view-footer {
  padding-top: $grid-gutter-width / 2;
  padding-bottom: $grid-gutter-width / 2;
}
.views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 24px;
}

@media (max-width: 1199px) {
    .view-id-featured_job {
        h3, h4 {
            margin-left: 0;
        }
    }
}

@media (max-width: 500px) {
    .view-jobs-search-index {
        .views-field-field-job-sector {
            display: none;
        }
    }
}

@media (max-width: 390px) {
    .view-jobs-search-index {
        .views-field-field-job-post-date {
            display: none;
        }
    }
}

#views-exposed-form-jobs-search-index-panel-pane-1 {
    .views-exposed-form .views-exposed-widget {
        float: none;
        clear: both;
        padding: 0;
        margin: 0;
    }

    .views-widget-filter-field_job_sector .form-group {
        width: auto;
        margin-right: 0;
        margin-top: 0;
    }

    .views-widget-filter-field_job_job_title {
        .form-group {
            width: auto;
            margin-right: unset;
            margin-top: unset;
            margin: 0px;
            display: inline-block;
        }
        .form-item-edit-field-job-job-title-all {
            display: block;
        }
    }

    .views-widget-filter-field_job_sector .form-item-edit-field-job-sector-4 {
        margin-right: 0;
    }
    .form-type-bef-link {
        a {
            display: inline-block;
            padding: 15px 15px 13px 30px;
            color: $brand-neutral-medium;
            line-height: 15px;
            margin-bottom: 4px;
            border: 1px solid $brand-neutral-light-mid;
            border-radius: 5px;
            background-color: $body-bg-white;
    
            background-position: 8px 13px;
            background-repeat: no-repeat;
            background-size: 18px 17px;

            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: $body-bg-white;
                background-color: $brand-secondary-accent;
            }
            &.active {
                color: $body-bg-white;
                background-size: 18px 17px;
                background-color: $brand-secondary-accent;
            }
        }
    }

    // .views-widget-filter-field_job_sector {
    //     a {        
    //     }
    // }

    .form-item-edit-field-job-job-title-all a,
    .form-item-edit-field-job-sector-all a {
        padding: 15px 10px 15px 0px;
        border: unset;
        background-color: unset;
        &:hover {
            text-decoration: none;
            color: unset;
            background-color: unset;
        }
        &.active {
            color: unset;
            background-size: 18px 17px;
            background-color: unset;
        }
    }

    #edit-field-job-job-title-1 {
        a {
            background-image: url("../images/job_icons/joblevel_senior.svg");
            &:hover, 
            &.active {
                background-image: url("../images/job_icons/joblevel_senior_hover.svg");
            }
        }
    }
    #edit-field-job-job-title-2 {
        a {
            background-image: url("../images/job_icons/joblevel_mid.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/joblevel_mid_hover.svg");
            }
        }
    }
    #edit-field-job-job-title-3 {
        a {
            background-image: url("../images/job_icons/joblevel_entry.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/joblevel_entry_hover.svg");
            }
        }
    }
    #edit-field-job-sector-1 {
        a {
            background-image: url("../images/job_icons/instructional_tech_icon.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/instructional_tech_icon_hover.svg");
            }
        }
    }
    #edit-field-job-sector-2 {
        a {
            background-image: url("../images/job_icons/info_tech.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/info_tech_hover.svg");
            }
        }
    }
    #edit-field-job-sector-3 {
        a {
            background-image: url("../images/job_icons/systems.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/systems_hover.svg");
            }
        }
    }
    #edit-field-job-sector-4 {
        a {
            background-image: url("../images/job_icons/applied.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/applied_hover.svg");
            }
        }
    }
    #edit-field-job-sector-5 {
        a {
            background-image: url("../images/job_icons/curriculum.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/curriculum_hover.svg");
            }
        }
    }
    #edit-field-job-sector-6 {
        a {
            background-image: url("../images/job_icons/corp.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/corp_hover.svg");
            }
        }
    }
    #edit-field-job-sector-7 {
        a {
            background-image: url("../images/job_icons/all_technology.svg");
            &:hover,
            &.active {
                background-image: url("../images/job_icons/all_technology_hover.svg");
            }
        }
    }
}

.view-id-info_per_applicant.view-display-id-block_1 {
    .views-label, .field-content {
        display: inline;
    }
    .views-field {
        margin-bottom: 10px;
    }
}

.view-id-info_per_applicant.view-display-id-block_2 {
    margin-top: 50px;
    .field-content {
        margin: 20px 0;
    }
}

.pane-info-per-applicant {
    margin: 25px 0 50px 0;
}

// .view-id-salary_survey_single_result {
//     margin-bottom: 50px;
//     .views-field {
//         padding: 14px 0;
//         border-bottom: solid 1px #efefef;
//     }
//     .views-label,
//     .field-content {
//         display: table-cell;
//     }
//     // .views-label {
//     //     width: 200px;
//     //     font-weight: 500;
//     //     &:before {
//     //         content: "\f0da";
//     //         font-family: Fontawesome;
//     //         margin-right: 12px;
//     //         color: #ddd;
//     //     }
//     // }
//     h3.views-label {
//         width: 100%;
//         &:before {
//             display: none;
//         }
//     }
//     .views-field-nothing,
//     .views-field-nothing-1,
//     .views-field-nothing-2,
//     .views-field-nothing-3 {
//         border-bottom: solid 1px #333;
//         margin-top: 30px;
//     }
// }

// @media (max-width: 390px) {
//     .view-id-salary_survey_single_result .views-label {
//         display: inline;
//     }
//     .view-id-salary_survey_single_result .field-content {
//         margin-left: 18px;
//         display: block;
//     }
// }

// @media (min-width: 391px) {
//     .view-id-salary_survey_single_result .views-label,
//     .view-id-salary_survey_single_result .field-content {
//         display: table-cell;
//     }
// }

@media (min-width: 990px) {
    .panel-pane.pane-slideshow-job-node-panel-pane-1{
        padding:0;
    }
}

.views-field-field-slideshow-image img{
    max-width: 300px;
}
.view-display-id-panel_pane_4 .view-header .messages{
    background-color: #dff0d8;
    border-color: #76ad5f;
    padding: 10px 10px 10px 20px;
}

.view-display-id-panel_pane_4 .view-header .highlight-job-count{

    font-size: 17px;
    color: #165017;
}

.view-display-id-panel_pane_4 .view-header .join-job-member{
        font-size: 14px;
}

.view-display-id-panel_pane_4 .view-header .job-count-bold{
    font-weight: bold;
        .flexslider .slides img {
    }
}


.view-homepage-success-stories {
    .teaser--media {
        @media screen and (max-width: 1180px) {
            flex-direction: column;
            .teaser__heading {
                margin: 10px 0;
            }
        }
    }
}
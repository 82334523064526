@media (min-width: $screen-lg-min) {
  .navbar-collapse.collapse {
    display: flex !important;
    justify-content: flex-end;
  }
  .navbar-nav {
    margin: 0;
    margin-top: 34px;
  }
}

.navbar-nav {
  li {
    a {
      text-decoration: none;
    }
  }
}
#footer {
  .nav > li > a:hover, .nav > li > a:focus {
    background-color: transparent;
  }
  .nav > li > a {
    padding: 0;
  }
}

.navbar {
  padding-bottom: 0;
  margin-bottom: 0;
  border: 0;
}

header .navbar-nav {
  .dropdown-toggle {
    display: block;
  }
  li a:not(.active), li span.dropdown-toggle {
    color: #fff;
    text-decoration: none;
    font-family: "proxima-nova";
  }
  & > li > span,
  & > li > a {
    text-transform: uppercase;
  }
  li a {
    padding: 0;
  }
  li {
    padding: 0 0 32px 0;
    margin-right: 26px;
  }
  .fa-caret-down {
    border: none;
    background: url(../images/icon/icon_arrow-down.svg) no-repeat;
    background-position: 0px 7px;
    background-size: 14px 8px;
    width: 20px;
    height: 20px;
    float: right;
    margin-left: 6px;
    &:before {
      content: "";
    }
  }
  li.expanded:hover {
    cursor: pointer;
  }
  li.active .fa-caret-down {
    background: url(../images/icon/icon_arrow-down-yellow.svg) no-repeat;
    background-position: 0px 7px;
    background-size: 14px 8px;
  }
  li.active span.dropdown-toggle {
    color: $brand-primary;
  }
  & > li.open {
    box-shadow: inset 0 -6px 0 0 $brand-primary;
    span {
      color: $brand-primary;
    }
    .fa-caret-down {
      background: url(../images/icon/icon_arrow-down-yellow.svg) no-repeat;
      background-position: 0px 7px;
      background-size: 14px 8px;
    }
  }
  ul.dropdown-menu {
    border: none;
    padding-top: $grid-gutter-width;
    padding-left: $grid-gutter-width / 2;
    text-transform: capitalize;
    font-size: $font-size-base;
    font-weight: bold;
    background-color: $brand-primary-accent;
  }
}

.mega-menu,
#header .mega-menu .container {
  background: $brand-secondary-accent;
  a {
    color: #FFFFFF;
    padding: 10px 0;
    text-decoration: none;
    font-family: "proxima-nova";
    &.active {
      color: $brand-primary;
    }
  }
  .nav > li > a:hover,
  .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    color: #FFC868;
  }
}

.mega-menu {
  display: flex;
  position: absolute;
  top: -1000px;
  z-index: 10;
  width: 100%;
  padding: 40px 0px;
  transition: all 0.15s ease;
  opacity: 0;
}

.mega-menu.open {
  top: initial;
  opacity: 100;
}

.mega-menu .block-menu-block {
  float: left;
}

.mega-menu .block-menu-block.resources {
  margin-left: 134px;
}

.mega-menu .block-menu-block.jobs {
  margin-left: 42px;
}

.mega-menu .block-menu-block.user {
  float: right;
}
.region-megamenu {
  width: 620px;
  float: right;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > li:hover > span,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background: transparent;
  color: $brand-primary;
}

.navbar-nav .open .dropdown-menu > .active > a,
.navbar-nav .dropdown-menu li a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a {
  background-color: transparent;
  color: $brand-primary;
}

// Mobile specific styles
@media (max-width: $screen-md-max) {
  header .navbar-nav {
    margin-top: $grid-gutter-width;

    .navbar-default .navbar-nav .open .dropdown-menu > .active > a {
      background-color: transparent;
      color: $brand-primary;
    }
    & > li {
      padding-top: $grid-gutter-width / 2;
      padding-bottom: $grid-gutter-width / 2;
      &.dropdown:not(.active) {
        border-bottom: 1px solid #fff;
      }
      &.open {
        span {
          color: $brand-primary;
        }
        .fa-caret-down {
          background: url('../images/icon/icon_arrow-down-yellow.svg') no-repeat;
          transform: scaleY(-1);
        }
      }
    }
  }
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #fff;
}

.site-toggle {
  display: none;
  @media screen and (min-width: 480px) {
    display: block;
  }
  width: 100%;
  padding: 0px 20px;
  background-color: #28292D;
  .flex {
    max-width: 1200px;
    width: 100%; 
    margin: 0px auto;
    justify-content: space-between;
    .first {
      color: #fff;
    }
    .second {
      display: flex;
      flex-direction: row;
      .toggle-12m {
        margin-right: 20px;
        &.active {
          background-color: #153846;
        }
        a {
          display: block;
          background-image: url('/sites/all/themes/edtechrecruiting/assets/images/logo/12m_hex_logo.png');
          background-repeat: no-repeat;
          background-size: 20px auto;
          background-position: left 10px center;
          padding-left: 40px;
          padding-right: 10px;
          color: #fff;
          line-height: 50px;
        }
      }
      .toggle-etr {
        &.active {
          background-color: #153846;
        }
        a {
          display: block;
          background-image: url('/sites/all/themes/edtechrecruiting/assets/images/logo/etr_circle_logo.svg');
          background-repeat: no-repeat;
          background-size: 20px auto;
          background-position: left 10px center;          
          padding-left: 40px;
          padding-right: 10px;
          color: #fff;
          line-height: 50px;
        }
      }
    }
  }
}
#submitwarning {
  &.hidemodal {
    display:none;
  }
  
  &.showmodal {
    display: block;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color:rgba(255,255,255,0.5);

  }
  .submitmodalbg {

    /* transform: translateY(-50%); */
    /* transform: translateX(-50%); */
    height: 100vh;
    width: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .submitmodalcontent {
      width: 80%;
      height: auto;
      padding: 200px 80px;
      margin: auto;
      background-color: #fff;
      opacity: 1;
      @media screen and (max-width: 767px) {
        width: 90%;
        padding: 50px;
      }
      @media screen and (max-width: 480px) {
        padding: 50px 20px;
      }
      p.modaltext {
        text-align: center;
        font-size: 32px;
        line-height: 42px;
        font-family: "PT Serif", serif;
        @media screen and (max-width: 767px) {
          font-size: 20px;   
        }
      }
      div {
        text-align: center;
        margin-top: 20px;
        color: #fff;
        a {
          padding: 20px;
          background-color: $brand-success;
          color: #fff;        
          &:hover {
            background-color: $brand-secondary-accent;
          }
        }
      }
    }
  }  
}
// Styles for Nodes
// --------------------------------------------------
.teaser {
  padding: $grid-gutter-width / 2;
  display: flex;
  flex-direction: column;
}
.teaser--media {
  flex-direction: row;
  margin-bottom: $grid-gutter-width / 2;
  .teaser__image {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-right: $grid-gutter-width / 2;
  }
  .teaser__heading {
    font-size: 1.75rem;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: $grid-gutter-width * 0.75;
  }
  .teaser__cta a.btn {
    background: transparent;
    border: 2px solid darken($brand-primary, 10%);
    &:hover {
      background: transparent;
      color: #333;
      border: 2px solid $brand-primary;
    }
  }
}
.teaser {
  .teaser__cta a.btn {
    background: transparent;
    border: 2px solid darken($brand-primary, 10%);
    &:hover {
      background: transparent;
      color: #333;
      border: 2px solid $brand-primary;
    }
  }
}
.field-name-field-article-subtitle {
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 10px;
}

h1.title {
  font-size: 28px;
}

.pane-job-posting-panel-pane-2.panel-pane {
  background: #F5F5F5;
  padding: 5px 24px 25px;
  margin-bottom: 50px;
  .views-label {
    font-weight: 600;
  }
  .views-field {
    margin-bottom: 20px;
  }
  .view-job-level span.class48 {
    background: url(../images/job_icons/senior.png) no-repeat;
  }
  .view-job-level span.class49 {
    background: url(../images/job_icons/middle.png) no-repeat;
  }
  .view-job-level span.class50 {
    background: url(../images/job_icons/entry.png) no-repeat;
  }
  .view-job-level span.class73 {
    background: url(../images/job_icons/teaching.png) no-repeat;
  }
  .view-job-level .field-content span,
  .view-job-sector .field-content span {
    height: 36px;
    display: block;
    padding: 8px 0 0 44px;
  }
  .view-job-sector span.Curriculum{
    background: url(../images/job_icons/curriculum.png) no-repeat;
  }
  .view-job-sector span.Instructional.Technology{
    background: url(../images/job_icons/ed-tech.png) no-repeat;
  }
  .view-job-sector span.Systems{
    background: url(../images/job_icons/systems.png) no-repeat;
  }
  .view-job-sector span.Information.Technology{
    background: url(../images/job_icons/it.png) no-repeat;
  }
  .view-job-sector span.Applied.Tech{
    background: url(../images/job_icons/maker.png) no-repeat;
  }
  .view-job-sector span.Corporate{
    background: url(../images/job_icons/corporate.png) no-repeat;
  }
  .view-job-sector span.All.Technology{
    background: url(../images/job_icons/all-tech.png) no-repeat;
  }
  .views-field-title .field-content{
    display: block;
  }
  h4{
    color: #F4A10B;
    text-transform: uppercase;
    font-size: 16px;
    border-bottom: solid 1px;
    padding-bottom: 4px;
  }
}

.pane-job-posting-panel-pane-1.panel-pane {
  .views-field-field-job-posted-documents {
    background: #428bca;
    padding: 10px 20px;
    color: #fff;
    margin: 0 0 20px 0;
    display: inline-block;
    a {
      color: #fff;
    }
  }
}
.views-field-field-job-link{
  background: rgb(68, 84, 106);
  padding: 10px 20px;
  color: #fff;
  margin: 0 0 20px 0;
  display: inline-block;
  a{
    color:#fff;
  }
}

.node-type-job .page-header h1.title {
  padding-bottom: 0;
}
.node-type-job .page-header{
  padding-bottom: 10px;
}

.node-type-job .school-title a{
  font-size: 16px;
  text-transform: none;
  border-bottom: solid 1px #ddd;
  width: 100%;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color:#455469;
}

.panel-pane.pane-school-for-a-job-posting {
  background: #eee;
  border-radius: 5px;
  padding: 1px 24px 15px;
  h4 {
    border-bottom: solid 2px;
    padding-bottom: 5px;
  }
}

.node-webform .submitted {
  display: none;
}

.container img.file-icon {
  width: auto;
}

.btn-link {
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #428bca;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: inherit;
  line-height: normal;
  padding: 1em;
  text-align: center;
  text-decoration: none !important;
  -webkit-transition: background .2s;
  transition: background .2s;
}

.btn-link:hover,
.btn-link:focus {
  background: rgba(66, 139, 202, 0.03);
  border: 1px solid #428bca;
}

.pane-apply-now-to-job{
  padding: 0;
}

.view-id-apply_now_to_job{
  background: #2057de;
  padding: 10px 20px;
  color: #fff;
  margin: 0 0 20px 0;
  display: inline-block;

  a{
    color:#fff;
  }
}

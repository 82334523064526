.flexslider {
  margin: 0;
  .slides {
    // Reset what flexslider does to images.
    img {
      width: initial;
      display: initial;
    }
  }
}
.flex-control-paging {
  bottom: $grid-gutter-width / 4;
  z-index: 2;
  li a {
    background: white;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    &.flex-active {
      background: $gray;
    }
  }
}


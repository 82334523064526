.button {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 6px;
  color: #28292d;
  font-weight: 700;
  text-decoration: none;
  font-family: "proxima-nova";
  &:hover {
    color: #28292d;
  }
  &.primary {
    background-color: $brand-primary;
    &:hover {
      background-color: $brand-primary-hover;
      color: #28292d;
    }
  }
  &.secondary {
    border: 2px solid $brand-primary;
    background-color: #fff;
    &:hover {
      border: 2px solid $brand-primary-hover;
      background-color: #fff;
      color: #28292d;
    }
  }

  &.plus-mark {
    background-image: url('../images/icon/post_job_plus_mark.svg');
    background-size: 17px;
    background-position: left 20px center;
    background-repeat: no-repeat;
    padding-left: 46px;
  }
    
  &.tertiary {}

  &.external {
    background-image: url('../images/icon/external-link-icon.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
  }
  &.paper {
    background-image: url('../images/icon/paper-link-icon.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
  }
}

.post-job-page-container {
  #content {
    max-width: 1140px;
    width: 100%;
    margin: auto;
    .jobheader {
      .row {
        .col-md-8 {
          h1 {
            margin-top: 0;
          }
        }
        .col-md-4 {
          @media screen and (min-width: 769px) {
            text-align: right;
          }
          .btn {
            margin-bottom: 20px;
            &:hover {
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .radix-layouts-sidebar {
      .pane-content {
        p:first-child {
          font-family: "proxima-nova";
          font-size: 18px;
          line-height: 24px;
          text-transform: uppercase;
        }
        ul {
          list-style: none;
          margin: 0px;
          padding: 0px;
          li {
            margin: 0px;
            padding: 0px;
            margin-bottom: 10px;
            font-family: "proxima-nova";
            font-size: 16px;
            line-height: 24px;
            text-transform: normal;
            list-style: none;
            background-image: url('/sites/all/themes/edtechrecruiting/assets/images/job_icons/blue_checkmark.svg');
            background-repeat: no-repeat;
            background-position: 0 3px;
            padding-left: 25px;
          }
        }
      }
    }
    .radix-layouts-content {
      .pane-content {
        .pricing-label {
          font-family: "proxima-nova";
          font-size: 18px;
          line-height: 24px;
          text-transform: uppercase;
        }
        .flex {
          justify-content: space-between;
          
          width: 100%;
          flex-direction: column;
          @media screen and (min-width: 769px) {
            flex-direction: row;
            max-width: 620px;
          }
          .item {            
            width: 100%;
            background-color: #fff;
            text-align: center;
            padding: 20px 40px;
            margin-bottom: 20px;                        
            @media screen and (min-width: 769px) {
              max-width: 170px;
              margin-bottom: 0;
            }

            p:first-child {
              font-family: "proxima-nova";
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;              
              margin: auto;
              margin-bottom: 10px;
            }
            p.price {
              font-family: "PT Serif";
              font-size: 30px;
            }
          }
        }

        .feature {
          
          width: 100%;
          background-color: #fff;
          padding: 40px 40px;
          margin-top: 40px;
          margin-bottom: 60px;
          @media screen and (min-width: 769px) {
            max-width: 620px;
          }
          .center {
            text-align: center;
            p:first-child {
              font-family: "proxima-nova";
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;              
              margin: auto;
              margin-bottom: 10px;
            }
            p.price {
              font-family: "PT Serif";
              font-size: 30px;
              margin-bottom: 20px;
            }
          }
          .featurebody {            
            ul {
              margin: 0;
              margin-left: 20px;
              padding: 0;
              li {
                margin: 0px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

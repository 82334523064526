// Styles for panels and panes.
// --------------------------------------------------
.panel-pane {
  padding: 0 15px;
}

.row.full,
.full-width-panes .row,
.no-page-manager-page .row {
  margin-left: -15px;
  margin-right: -15px;
}

.pane-bundle-icon-text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  margin-bottom: 30px;
  .pane-title {
    display: none;
  }
  h2 {
    font-size: 22px;
  }
  .field-name-field-icon-text-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.radix-layouts-slidergutter {
  .field-name-field-pane-image {
    text-align: center;
  }
}

.page-frontpage .pane-bundle-image-text {
  max-width: 290px;
  margin: 0 auto;
}

.field-name-field-pane-image img {
  width: 100%;
  height: auto;
}

.heading-2.service-h2 {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}

.heading-3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}

.field-name-field-image-text-image {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pane-bundle-homepage-banner,
.pane-homepage-banner-slideshow {
  padding: 0;
  position: relative;
  .views-field-field-banner-image {
    width: 50%;
  }
  .group-text-box {
    padding: 60px 60px;
  }
  .group-text-box .btn {
    margin-top: 30px;
  }
  .field-name-field-homebanner-lineone,
  .field-name-field-banner-line-1 {
    text-transform: uppercase;
  }
  .field-name-field-homebanner-linetwo,
  .field-name-field-banner-line-2 {}
}

.radix-layouts-hero {
  background: $brand-primary;
}

.pane-testimonials-panel-pane-1 {
  margin: 50px 0;
  padding: 0 10%;
  .views-field-field-testimonial-quote, .views-field-title {
    color: #fff;
    font-size: 20px;
    font-style: italic;
  }
  .views-field-title {
    margin-top: 20px;
    text-align: right;
  }
}

.pane-fpid-10 {
  background: #44546A;
  padding: 25px;
  margin: 25px 0;
  h2 {
    margin: 0;
  }
}

.pane-bundle-image-text {
  .field-name-field-image-text-url {
    background: #44546A;
    padding: 15px 5px 0 5px;
    text-align: right;
    margin: 15px 0 45px 0;
    a {
      color: #fff;
      font-style: italic;
    }
  }
  h2 {
    font-size: 20px;
    color: #44546A;
    text-transform: capitalize;
    margin-bottom: 2px;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.flexslider {
  background: none;
}

.view-featured-job {
  .flexslider .slides {
    display: flex !important;
    li {
      display: flex !important;
    }

    .job-slide-group {
      @extend .list-unstyled;
      flex-direction: column;
      display: flex !important;
      flex-grow: 1;
      @media (min-width: $screen-lg-min) {
        flex-direction: row;
        margin-left: $grid-gutter-width * 2;
        margin-right: $grid-gutter-width * 2;
      }
    }
    .job-slide {
      display: flex !important;
      flex-grow: 1;
      width: 100%;
      padding: ($grid-gutter-width / 4) ($grid-gutter-width / 2);
    }
  }
  .flex-viewport {
    margin: 0 50px;
  }
  .views-field-field-logo {
    float: left;
    margin-right: 15px;
    margin-bottom: 0;
    border: solid 1px $brand-neutral-light-mid;
  }
  .views-field-title a {
    font-weight: 700;
  }
  .job-title {
    color: $brand-neutral-mid;
    margin-top: 5px;
    display: block;
  }
  .view-footer {
    text-align: center;
  }
}

.pane-featured-job-panel-pane-1 h4 {
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 16px
}

.radix-layouts-topmiddle {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
}

.radix-layouts-middle {
  background: #fff;
  padding: 72px 0;
  .pane-custom {
    text-align: center;
    h4 {
      text-transform: uppercase;
    }
    h2 {
      margin-top: 10px;
      margin-bottom: 54px;
    }
  }
}

.radix-layouts-columns {
  .col-md-12 {
    text-align: center;
    margin-bottom: 42px;
    margin-top: 33px;
  }
  .col-md-4 {
    text-align: center;
    display: flex;
    h2 {
      font-family: "proxima-nova";
      font-size: 18px;
      color: #4E4E4E;
      letter-spacing: 1px;
      line-height: 21px;
      margin-bottom: 12px;
      text-align: center;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 5px;
    }
    .pane-bundle-image-text-button {
      box-shadow: 0px 2px 24px 0px rgba(114, 113, 109, 0.15);
      padding: 18px 18px;
      background: #fff;
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
    }
    .field-name-field-image-text-button-text {
      margin: 23px;
      min-height: 112px;
    }
    .field-name-field-image-text-button-link a {
      color: $brand-secondary-accent;
      text-transform: uppercase;
      width: 100%;
      display: block;
      border-top: solid 1px $brand-primary;
      text-decoration: none;
      padding-top: 10px;
      font-weight: 700;
      cursor: pointer;
    }
    .field-name-field-image-text-button-link a:hover {
      color: #0E5A6C;
    }
  }
}

.radix-layouts-submenu {
  margin-top: 20px;
  ul.nav {
    border-left: solid 1px #DBD8D7;
  }
  .nav-pills > li > a {
    color: #72716D;
    text-decoration: none;
    text-transform: uppercase;
    padding-left: 25px;
    margin-bottom: 8px;
    letter-spacing: 1.3px;
    &:hover, &.active {
      color: $brand-secondary-accent;
    }
    &.active {
      background: #F2F1F0;
      border-left: solid 5px $brand-primary;
      padding-left: 20px;
    }
  }
}

.page-services-assess .radix-layouts-middle .pane-bundle-text,
.page-services-transition .radix-layouts-middle .pane-bundle-text {
  max-width: 784px;
  margin: 0 auto;
  h2 {
    margin-bottom: 36px;
  }
  .number-section {
    @extend .col-md-6;
    display: flex;
    text-align: center;
    justify-content:center;
    flex-direction: column;
    @media(min-width: $screen-lg-min) {
      text-align: left;
    }
    p {
      font-size: 24px;
      line-height: 30px;
      max-width: 328px;
    }
  }
  .number {
    font-size: 30px;
    font-family: 'PT Serif';
    margin: 30px 0 10px;
    display: block;
  }
}

.pane-bundle-services-assess-case-study {
  padding-bottom: 56px;
  border-bottom: solid 1px #DBD8D7;
  margin-bottom: 85px;
  text-align: center;
  @media (min-width: $screen-lg-min) {
    text-align: left;
  }
  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .pane-title {
    display: none;
  }
  h2 {
    margin-top: 0;
  }
  .field-name-field-assess-subtitle {
    font-family: "proxima-nova";
    font-size: 18px;
    color: #4E4E4E;
    letter-spacing: 1px;
    line-height: 21px;
    margin-bottom: 12px;
  }
  .field-name-field-assess-image {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
  }
  .field-type-text-long .field-label {
    font-family: 'PT Serif';
    font-size: 30px;
    color: #28292D;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
    font-weight: 500;
  }
  .field-type-text-long {
    margin-bottom: 36px;
  }
  .group-left,
  .group-right {
    @extend .col-md-6;
  }
}

.panel-display.services .top {
  margin: 108px 0;
  margin-bottom: 20px;
}

.wrapper-white {
  background: #fff;
}

.wrapper-blue {
  background: $brand-secondary-accent;
}

.radix-layouts-overview {
  h2.pane-title {
    font-family: "proxima-nova";
    font-size: 18px;
    color: #4E4E4E;
    letter-spacing: 1px;
    line-height: 21px;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .field-name-field-panetext-text h3 {
    font-family: 'PT Serif';
    font-size: 30px;
    color: #28292D;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 25px;
  }
  .scrolldown {
    color: #4e4e4e;
    font-size: 16px;
    text-decoration: none;
    background: url(../images/icon/icon_arrow-scroll-down.svg) no-repeat;
    background-size: 14px 14px;
    background-position: left center;
    padding-left: 24px;
    font-family: "proxima-nova";
  }
}

.pane-bundle-section-title h2 {
  text-align: center;
  margin-bottom: 50px;
}

.field-name-field-image-text-button-text {
  margin: 30px 0;
}

#services-content .field-name-field-image-text-button-link a {
  padding: 12px 24px;
  border-radius: 6px;
  color: $brand-neutral-dark;
  font-weight: 700;
  text-decoration: none;
  font-family: "proxima-nova";
  background: $brand-primary;
  border: solid 2px $brand-primary;
  display: inline-block;
  &:hover {
    background: #F0AF3C;
    border: solid 2px #F0AF3C;
  }
}

.services .radix-layouts-middle {
  .pane-bundle-image-text-button {
    @extend .col-md-4;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .field-name-field-image-text-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.radix-layouts-featured,
.radix-layouts-header {
  padding: 72px 0;
  h3 {
    color: #fff;
    max-width: 474px;
    line-height: 45px;
    padding-top: 40px;
    font-weight: 500;
  }
}

.wrapper-white .radix-layouts-header h3 {
  color: $brand-neutral-dark;
}

.radix-layouts-bottom {
  margin: 72px 0;
  .pane-bundle-icon-text {
    @extend .col-md-4;
  }
}

.page-services-transition .wrapper-blue,
.page-services-recruit .wrapper-blue {
  display: none;
}

.page-services-recruit .wrapper-white:nth-child(2) {
  display: none;
}

.page-services-transition .wrapper-white:last-child {
  background: #F2F1F0;
}

@media (max-width: 1200px) {
  .view-featured-job {
    .views-field-field-logo {
      float: none;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 720px) {
  .view-featured-job {
    .views-field-field-logo {
      margin-top: 10px;
    }
  }
}

.page-frontpage .radix-layouts-pre-footer .panel-panel-inner {
  background: #f7bb4b;
  h2 {
    color: #fff;
  }
}

@media (max-width: 710px) {
  .pane-featured-job-panel-pane-1 {
    width: 100%;
    margin: 0 auto;
  }
}

.views-field-field-job-image {
  float: left;
  margin-right: 40px;
}

.flex-direction-nav a {
  line-height: 40px;
}

.pane-fpid-19,
.pane-fpid-21 {
  background-color: rgba(69, 84, 105, 0.85);
  z-index: 400;
  height: 100%;
  color: #fff;
  float: right;
  padding: 10px 51px 37px;
  h2 {
    color: #fff;
    margin-bottom: 15px;
  }
}

.orange-button {
  background: #f7bb4b;
  padding: 15px 15px 15px 15px;
  margin: 0 auto;
  color: black;
  display: block;
  width: 203px;
  text-align: center;
}

.pane-fpid-19 {
  width: 30%;
}

.pane-fpid-21 {
  margin-top: 30px;
  width: 88%;
}

.panel-layout-edtechrecruit_lake .radix-layouts-footer {
  position: relative;
  margin: 45px 0 0;
}

.view-id-showcase.view-display-id-panel_pane_1 {
  width: 85%;
  margin-left: 100px;
}

.pane-showcase-panel-pane-1 {
  width: 70%;
  float: left;
}

.radix-layouts-middlebottom .pane-bundle-text {
  text-align: center;
  max-width: 752px;
  margin: 0 auto;
}

.radix-layouts-middletop,
.radix-layouts-middlebottom {
  padding: 72px 0;
}

.pane-bundle-about-us-project-leads {
  text-align: center;
  margin: 42px auto 0;
  width: 364px;
  .pane-title {
    display: none;
  }
  .field-name-field-about-lead-photo {
    margin: 0 auto;
    img {
      max-width: 170px;
      height: auto;
    }
  }
}

.view-associate-slideshow {
  .views-row {
    background: #fff;
    padding: 36px;
    margin-bottom: 24px;
    -webkit-box-shadow: 0px 2px 24px 0px rgba(114, 113, 109, 0.15);
    -moz-box-shadow: 0px 2px 24px 0px rgba(114, 113, 109, 0.15);
    box-shadow: 0px 2px 24px 0px rgba(114, 113, 109, 0.15);
    border-radius: 5px;
  }
  h3 {
    margin-top: 0;
  }
}

.page-articles {
  .panel-display.services .top {
    margin: 108px 0 0;
  }
  .radix-layouts-header {
    display: none;
  }
}

.page-articles {
  .pane-bundle-text {
    clear: both;
    margin: 17px 0;
    display: table;
  }
  .wrapper-blue,
  .wrapper-white:nth-child(2) {
    display: none;
  }
  .featured-one {
    @extend .col-md-6;
    margin-top: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    text-align: center;
    h3 {
      margin: 25px;
    }
    h6 {
      margin: 32px 0 15px;
    }
    h6 a {
      text-decoration: none;
      color: #000;
    }
  }
  .featured-three {
    @extend .col-md-6;
    @media (min-width: $screen-lg-min) {
      border-left: solid 1px #DBD8D7;
      padding-left: 24px;
    }
    h3 {
      font-size: 21px;
      line-height: 27px;
      margin-top: 0;
      margin-bottom: 22px;
    }
    h6 a,
    h3 a {
      text-decoration: none;
      color: #000;
    }
    .views-field-field-image {
      float: left;
      width: 37%;
      height: auto;
      margin-left: 21px;
      margin-top: 0;
    }
    .views-row {
      clear: both;
      height: 170px;
      margin-bottom: 42px;
    }
  }
  .featured-three.left {
    @media (min-width: $screen-lg-min) {
      padding-left: 0;
      padding-right: 24px;
      border-left: 0;
      border-right: solid 1px #DBD8D7;
      width: 42%;

    }
    .views-field-field-image {
      margin-left: 0;
      margin-right: 24px;
    }
  }
  .featured-one.right {
    padding-right: 0px;
    padding-left: $grid-gutter-width;
    @media (max-width: $screen-md-max) {
      padding-left: 0px;
    }
  }
  .featured-six {
    border-top: solid 1px #DBD8D7;
    border-bottom: solid 1px #DBD8D7;
    padding: 54px 0 8px;
    margin-bottom: 65px;
    h3 {
      font-size: 21px;
      margin: 6px 0;
    }
    h6 {
      margin: 0;
    }
    .views-field-view-node {
      margin-bottom: 54px;
      a {
        color: $brand-secondary-accent;
        text-decoration: none;
      }
    }
  }
}

.node-type-article {
  .r,
  .banner-right adix-layouts-submenu .nav-pills > li.menu-link-articles a {
    color: $brand-secondary-accent;
    background: #F2F1F0;
    border-left: solid 5px $brand-primary;
    padding-left: 20px;
  }
  .field-name-field-article-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0;
    font-family: "Pt serif";
  }
  .field-name-field-image {
    margin: 46px 0;
  }
  .row.top {
    margin-top: 76px;
  }
  .field-name-field-article-author {
    float: left;
    margin: 34px 0;
    padding: 48px 0 0;
    border-top: solid 1px #DBD8D7;
    .field-name-field-author-photo {
      float: left;
      margin-right: 24px;
    }
    h6 {
      font-size: 16px;
      margin: 0;
      padding: 0;
      line-height: 21px;
    }
    p {
      line-height: 21px;
      font-family: "pt serif";
      font-size: 16px;
      color: $brand-neutral-mid;
      margin-left: 96px;
    }
  }
}

.radix-layouts-left,
.radix-layouts-right {
  margin: 76px 0;
}

.phone {
  background: url(../images/icon/icon_phone.svg);
  background-size: 13px 18px;
  background-repeat: no-repeat;
  background-position: left;
  width: 130px;
  padding-left: 20px;
}
.social-icon {
  padding-right:10px;
  img {
    width: 22px;
    height: 22px;
  }
}

.form-control{
  -webkit-box-shadow: 0px 2px 24px 0px rgba(114, 113, 109, 0.15);
  -moz-box-shadow: 0px 2px 24px 0px rgba(114, 113, 109, 0.15);
  box-shadow: 0px 2px 24px 0px rgba(114, 113, 109, 0.15);
  border-radius: 0;
  border:0;
}

.pane-facetapi {
  .item-list ul li {
    list-style: none;
    margin: 2px 0;
    padding: 0;
  }
  .facetapi-checkbox {
    margin: 0;
  }
  input.facetapi-checkbox {
    margin-right: 10px;
  }
}

.facetapi-facetapi-checkbox-links {
  ul {
    padding-left: 20px;
  }
}

.view-showcase-recruit {
  .views-field.views-field-nothing-1 {
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 50px;
  }
  .card {
    .card-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      @media (min-width: $screen-lg-min) {
        flex-direction: row;
        .btn {
          margin-left: $grid-gutter-width * 2.5;
        }
      }
    }
    .card-lockup {
      display: flex;
      flex-direction: column;
      .views-field-title {
        margin-top: 0;
        text-transform: uppercase;
      }
    }
    .card-lockup__top {
      display: flex;
    }
    .card-top__org {
      margin-top: $grid-gutter-width / 3;
      margin-bottom: $grid-gutter-width;
    }
    .card-logo {
      margin-right: $grid-gutter-width / 2;
    }
    .card-text {
      margin-top: $grid-gutter-width;
    }
    .school {
      clear: both;
    }
    .data {
      margin-top: 26px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .group {

        margin-bottom: $grid-gutter-width / 2;
      }
      @media (min-width: $screen-lg-min) {
        flex-direction: row;
      }
    }
    .summary {
      clear: both;
      margin-top: 26px;
      display: block;
    }
    .views-field-field-showr-document a {
      position: static;
      display: block;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
      padding: 9px 15px;
      border-radius: 10px;
      background-color: #f7b955;
      -webkit-transition: background-color 350ms ease;
      transition: background-color 350ms ease;
      font-family: 'Open Sans', sans-serif;
      color: #3b3a3a;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        background-color: #44546a;
        color: #fff;
      }
    }
  }
  .card-imagery img {
    width: 100%;
  }
  .views-field-field-showr-pivotcircle img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }
  .views-field {
    padding: 0;
  }
  .views-field.views-field-nothing {
    padding: 0;
  }
}

.panel-panel-inner:before,
.panel-panel-inner:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */
}

.panel-panel-inner:after {
  clear: both;
}

.pane-menu-services {
  width: 300px;
  margin-top: 55px;
  margin-right: 15px;
  margin-bottom: 25px;
  margin-left: 25px;
  padding: 15px 25px;
  float: right;
  background-color: #f0f0f0;
  h2 {
    margin: 0;
    margin-top: 0px;
    font-size: 25px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .nav-pills > li > a {
    padding: 3px 0;
  }
  .nav > li > a:hover,
  .nav > li > a:focus {
    background: none;
  }
  .nav-pills > li.active > a,
  .nav-pills > li.active > a:hover,
  .nav-pills > li.active > a:focus {
    background-color: transparent;
    color: #474747;
    font-weight: 700;
  }
}

@media (max-width: 650px) {
  .pane-menu-services {
    width: auto;
    margin-bottom: 25px;
    padding: 15px 25px;
    float: none;
    background-color: #f0f0f0;
    margin-top: 55px;
    margin-right: 15px;
    margin-left: 15px;
    box-sizing: content-box;
  }
}

.page-services-recruit #page-header {
  display: none;
}

.transition-row-1 {
  margin-bottom: 80px;
}

.edtechrecruit-seaborn {
  margin-bottom: 80px;
}
/* Mobile */

@media (max-width: 1200px) {
  .page-frontpage .radix-layouts-pre-footer .panel-panel-inner .pane-bundle-image-text {
    width: 50%;
    float: right;
    margin-right: 30px;
  }
  .page-frontpage .radix-layouts-pre-footer .panel-panel-inner .pane-featured-job-panel-pane-1 {
    width: 50%;
    margin-left: 30px;
  }
  .row.full,
  .full-width-panes .row,
  .no-page-manager-page .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 710px) {
  div {
    word-wrap: break-word;
  }
  .navbar-default .navbar-toggle {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .pane-bundle-homepage-banner .group-content,
  .pane-homepage-banner-slideshow .views-field-nothing {
    position: static;
    background-color: #44546A;
    bottom: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    display: block;
    font-size: 80%;
  }
  .pane-bundle-homepage-banner .group-text-box,
  .pane-homepage-banner-slideshow .group-text-box {
    position: relative;
    display: block;
    bottom: 0;
    padding: 3%;
    left: 0;
  }
  .page-frontpage .radix-layouts-pre-footer .panel-panel-inner .view-featured-job .slides {
    padding: 1% 10%;
  }
  .pane-showcase-panel-pane-1 {
    width: 100%;
  }
  .pane-bundle-homepage-banner {
    .field-name-field-homebanner-lineone, .field-name-field-homebanner-linetwo, .field-name-field-homebanner-linethree, .field-name-field-homebanner-methods {
      font-size: 21px;
    }
    .field-name-field-homebanner-methods {
      padding: 0;
    }
  }
  .pane-homepage-banner-slideshow {
    .field-name-field-banner-line-1, .field-name-field-banner-line-2, .field-name-field-banner-line-3, .field-name-field-banner-methodologies {
      font-size: 21px;
    }
    .field-name-field-banner-methodologies {
      padding: 0;
    }
  }
  .pane-fpid-19 {
    width: 100%;
    margin: 0;
    display: block;
  }
  .view-id-showcase.view-display-id-panel_pane_1 {
    width: 100%;
    margin-left: 0;
  }
  .panel-layout-edtechrecruit_lake .radix-layouts-footer {
    margin: 40px 0 0;
  }
  .page-frontpage .radix-layouts-pre-footer .panel-panel-inner .pane-featured-job-panel-pane-1,
  .page-frontpage .radix-layouts-pre-footer .panel-panel-inner .pane-bundle-image-text {
    width: 100%;
    margin: 0;
    max-width: none;
  }
  .view-id-all_article_titles.square-blocks .views-row {
    width: 45%;
    margin-right: 15px;
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .tabs,
  .nav-tabs {
    margin: 0;
  }
  .page-header {
    margin: 0;
    padding-left: 0;
    border-bottom: 0;
  }
  .page-header {
    padding-top: 20px;
  }
  #content {
    padding: 5%;
  }
  .navbar-brand {
    margin: 0 auto;
    height: auto;
  }
  .pane-bundle-banner-image-text .text-box {
    position: relative;
    width: 100%;
  }
  .pane-methodology-overview-panel-pane-1 {
    margin: 0;
  }
  .region-pre-footer {
    padding: 5% 3%;
  }
  #footer .container {
    padding: 5% 3%;
    .region {
      margin-bottom: 40px;
    }
  }
  .pane-bundle-icon-text {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .pane-bundle-about-us-principle-bio .field-name-field-about-principle-bio,
  .pane-bundle-about-us-principle-bio .pane-title {
    margin-left: 0;
  }
  .pane-bundle-about-us-principle-bio {
    padding: 20px 30px 0px 30px;
  }
  .pane-bundle-about-us-principle-bio .field-name-field-about-principle-photo {
    position: relative;
  }
  .pane-associate-slideshow-panel-pane-1 {
    margin: 0 3%;
  }
  .pane-fpid-21 {
    margin-top: 0;
    width: 100%;
  }
  .pane-bundle-about-us-principle-bio .field-name-field-about-principle-photo img {
    max-width: 300px;
  }
  .pane-fpid-19,
  .pane-fpid-21 {
    padding: 3% 3% 30px 3%;
  }
  .field-name-field-image-text-image img {
    max-width: 300px;
  }
  .pane-bundle-image-text {
    max-width: 300px;
    margin: 0 auto;
  }
  .pane-fpid-21 {
    max-width: none;
    margin-bottom: -1px;
  }
}

@media (max-width: 710px) {
  .field-name-field-icon-text-image img {
    max-width: 50px;
  }
}

@media (max-width: 450px) {
  .view-id-all_article_titles.square-blocks .views-row {
    width: 100%;
  }
  .view-id-all_article_titles.square-blocks .views-row:hover .views-field-field-article-subtitle {
    margin-top: 8%;
  }
}

@media (min-width: $screen-md-min) {
  .pane-homepage-success-stories-panel-pane-1 {
    padding-right: $grid-gutter-width;
  }
  .pane-homepage-success-stories-panel-pane-2 {
    border-left: solid 1px #DBD8D7;
    padding-left: $grid-gutter-width * 2;
  }
}
.radix-layouts-columns .pane-1 h2 {
  text-align: center;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
}

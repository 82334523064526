// Typography
// --------------------------------------------------

h1{
  color: #28292D; 
  font-family: 'PT Serif';
  font-size: 24px;
  line-height: 27px;
  font-weight: 700; 
  letter-spacing: 0;
  margin-bottom: 6px;  
  @media screen and (min-width: 769px) {
   font-size: 54px;  
   line-height: 63px; 
   margin-bottom: 12px;  
  }
  
}

h2{
  font-family: 'PT Serif'; font-weight: 700; font-size: 45px; color: #28292D; letter-spacing: 0; line-height: 51px; margin-bottom: 12px;
  @media (max-width: $screen-md-max) {
    font-size: 30px;
  }
}

h3{
  font-family: 'PT Serif'; font-size: 30px; color: #28292D; letter-spacing: 0; line-height: 36px; margin-bottom: 12px;
  @media (max-width: $screen-md-max) {
    font-size: 25px;
  }
}

h4{
  font-family: "proxima-nova"; font-size: 18px; color: $brand-neutral-medium; letter-spacing: 1px; line-height: 21px; margin-bottom: 12px;
}

h5{
  font-family: 'PT Serif'; font-weight: 700; font-size: 15px; color: $brand-neutral-medium; letter-spacing: 0; line-height: 18px; margin-bottom: 12px;
}

h6{
  font-family: "proxima-nova"; font-weight: 700; font-size: 16px; color: $brand-neutral-medium; letter-spacing: 0; line-height: 18px; margin-bottom: 12px;
}

a{
  color:$brand-secondary-accent;
  text-decoration: underline;
}

body{
  color:$brand-neutral-medium;
}

p{
  line-height: 27px;
}

.detail_label {
  font-family: "proxima-nova"; font-weight: 700; font-size: 16px; color: $brand-neutral-medium; letter-spacing: 0; line-height: 21px; margin-bottom: 12px;
}

.detail_value {
  font-family: "proxima-nova"; font-weight: normal; font-size: 24px; color: $brand-neutral-medium; letter-spacing: 0; line-height: 30px; margin-bottom: 24px;
}
body.node-type-job {
  #content.job-content-container {
    padding: 0;
    article {
      .job-header {
        // background-color: $brand-neutral-light;
        background-color: #F3F3F2;
        padding-top: 40px;
        padding-bottom: 40px;
        .job-logo {
          img {
            max-width: 56px;
            width: 100%;
            height: auto;
            @media screen and (min-width: 769px) {
              max-width: 72px;
            }
          }
        }
        .login-to-apply {
          margin-top: 0;
          text-align: center;
          @media screen and (min-width: 769px) {
            margin-top: 22px;
            text-align: unset;
          }
        }
        .job-location {
          padding-top: 10px;
          padding-bottom: 20px;
        }
        .view-id-job_post_sector_terms.view-display-id-embed_1,
        .view-job-post-sector-terms.view-display-id-embed_2 {
          ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
          }
          ul {
            li {
              display: inline;
              list-style: none;
              margin: 0;
              padding: 0;
            }
            li:after {
              content: ", ";
              position: relative;
              right: 0;
              margin-right: 0;
            }
            li:last-child:after {
              content: unset;
            }
          }
        }
      }
      .job-content{
        background-color: $body-bg-white;
        padding: 0px;
        padding-top: 40px;
        padding-bottom: 40px;
        .container {
          .job-photos-grid {
            .field-name-field-slideshow-image {
              .field-items {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .field-item {
                  max-width: 105px;
                  width: 100%;
                  height: auto;
                  margin: 5px;
                  img {
                    max-width: 105px;
                    width: 100%;
                    height: auto;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
          .right-column {
            > div {
              margin-bottom: 20px;
            }
          }
          .apply-buttons {
            gap: 10px;
            margin-bottom: 20px;
            .login_to_apply {
              a.button {
                border: 2px solid #E09B20;
              }
            }
          }
        }
      }
    }
  }
  
  .smallshow {
    .job-photos-grid {
      .field-name-field-slideshow-image {
        .field-items {
          max-width: 345px;
          width: 100%;
          margin: auto;
          justify-content: flex-start;
        }
      }
    }
    .job_original_link {
      text-align: center;
    }
    .job_opp_statement {
      text-align: center;
      @media screen and (max-width: 600px) {
        a.button {
          display: block;
          white-space: unset;                
        }
      }
    }
    .login_to_apply {
      text-align: center;
    }
  }
  
  
  .job-app-instructions {
    background-color: $brand-secondary-accent;
    color: #fff;
    padding: 40px 0;
    h2 {
      color: #fff;
      font-size: 30px;
      font-weight: normal;
    }
    .field-name-field-field-job-app-instructions {
      margin-bottom: 20px;
      .field-item {
        a {
          color: $brand-neutral-light-mid;
        }
      }
    }
  }

}

.view-hiring.view-display-id-page {
  .view-header {
    max-width: 1140px;
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
  }
  .views-row {
    max-width: 1140px;
    width: 100%;
    margin: auto;
    .node-job {
      box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.15);
      margin-bottom: 40px;
      padding: 20px;
    }
    .flex {
      flex-direction: column;
      justify-content: flex-start;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
      }
      
      .left {
        .images {
          .image {
            width: 100%;
            height: 300px;
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            @media screen and (min-width: 1024px) {
              width: 390px;
              height: 300px;
            }
            // .logo {
            //   position: absolute;
            //   top: 10px;
            //   left: 10px;
            // }
          }
        }
      }
      .right {
        max-width: 100%;
        width: 100%;
        @media screen and (min-width: 1024px) {
          max-width: calc(100% - 450px);
        }
        > .flex {
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .logo {
            margin-top: 10px;
            @media screen and (min-width: 769px) {
              float: left;
              max-width: 115px;
              width: 100%;
              height: auto;
              margin-right: 10px;
            }
            img {
              max-width: 115px;
              width: 100%;
              height: auto;
            }
          }
          .top {
            h2 {
              font-family: 'PT Serif';
              font-weight: normal;              
              color: #28292D;
              letter-spacing: 0;              
              margin: 0;
              margin-bottom: 10px;
              margin-top: 10px;
              font-size: 26px;
              line-height: 28px;
              @media screen and (min-width: 601px) {
                font-size: 32px;
                line-height: 36px;
              }
            }
            .job-location {
              margin-bottom: 20px;
              text-transform: uppercase;
            }
            .dates {
              flex-direction: row;
              justify-content: space-between;
              @media screen and (min-width: 601px){
                flex-direction: row;
                justify-content: flex-start;
              }
              .date {                
                margin-bottom: 10px;
                margin-right: 20px;
                &:last-child {
                  margin-right: 0;
                }
                @media screen and (min-width: 1024px) {
                  margin-right: 80px;
                  margin-bottom: 10px;
                }
                label {
                  font-family: "proxima-nova";
                  font-weight: normal;
                  color: #4e4e4e;
                  letter-spacing: 0;
                  margin-bottom: 0;
                  font-style: italic;
                  font-size: 14px;
                  line-height: 16px;
                  @media screen and (min-width: 601px){
                    font-size: 16px;
                    line-height: 21px;
                  }
                }
                .field-item {
                  font-family: "proxima-nova";
                  font-weight: normal;
                  color: #4e4e4e;
                  letter-spacing: 0;
                  margin-bottom: 12px;
                  font-size: 14px;
                  line-height: 16px;
                  @media screen and (min-width: 601px){
                    font-size: 16px;
                    line-height: 21px;
                  }
                }
                &.posted {}
                &.due {}
                &.start {}
              }        
            }
          }
          .bottom {
            border-top: 1px solid $gray-light;
            padding-top: 30px;
            .flex {
              flex-direction: column;
              justify-content: flex-start;
              @media screen and (min-width: 1170px) {
                flex-direction: row;
                justify-content: space-between;
              }
              .sector {
                background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/sector_checkmark.svg');
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 30px;
                margin-bottom: 20px;
                @media screen and (min-width: 1170px) {
                  margin-bottom: 0;
                }
                .field-name-field-job-sector {
                  height: 100%;
                  .field-items {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  }
                }
              }
              .level {
                margin-bottom: 20px;
                @media screen and (min-width: 1170px) {
                  margin-bottom: 0;
                }
                .view-hiring-job-rank-view-display {
                  height: 100%;
                  .view-content {
                    height: 100%;
                    .views-row {
                      height: 100%;
                      .views-field {
                        height: 100%;
                        .field-content {
                          display: block;
                          height: 100%;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          > div {
                            background-repeat: no-repeat;
                            background-position: left center;
                            padding-left: 30px;
                          }
                          .level-48 {
                            background-image: url(/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_senior.svg);
                          }
                          .level-49 {
                            background-image: url(/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_mid.svg);
                          }
                          .level-50 {
                            background-image: url(/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_senior.svg);
                          }
                          .level-73 {
                            background-image: url(/sites/all/themes/edtechrecruiting/assets/images/icon/joblevel_mid.svg);
                          }
                        }
                      }
                    }
                  }
                }
                .field-name-field-job-job-title {
                  .field-items {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  }
                }
              }
              .link {
                a.btn.green {
                  background-color: $brand-success;
                  color: #fff;
                  &:hover {
                    background-color: $brand-primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.view-hiring {
  .attachment-after {
    max-width: 1140px;
    width: 100%;
    margin: auto;
    margin-top: 100px;
    .view-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
      }
      .views-row {
        margin: 0;
        margin-bottom: 20px;
        @media screen and (min-width: 1024px) {
          margin: 0;
          margin-bottom: 0;
        }
        .node_job_coming_soon {
          max-width: 580px;
          width: 100%;
          .flex {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            @media screen and (min-width: 600px) {
              flex-direction: row;
              justify-content: space-between;
            }
            .left {
              max-width: 260px;
              width: 100%;
              margin-right: 10px;
              img {
                max-width: 260px;
                height: auto;
              }
            }
            .right {
              max-width: 260px;
              width: 100%;
              padding-right: 30px;
              .comingsoonlabel {
                text-transform: uppercase;
              }
              .title {
                padding: 22px 0;
                a {
                  font-family: 'PT Serif';
                  font-weight: normal;
                  font-size: 24px;
                  color: #4e4e4e;
                  letter-spacing: 0;
                  line-height: 28px;
                  margin-bottom: 20px;
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
              .job-location {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
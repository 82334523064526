#login-links {
  margin-bottom: $grid-gutter-width;
  .login-link {
    @extend .btn;
  }
  .lt-active {
    background-color: $brand-primary !important;
    border: 2px $brand-primary solid !important;
  }
}

// salary results list page
// body.page-salary,
// body.page-salary-results-2018,
// body.page-salary-results-2017,
// body.page-salary-results-2016,
body.page-salary-results.panel-layout-radix_burr_flipped {
  background-color: #fff;
  .container-fluid {
    max-width: 1140px;
    .radix-layouts-sidebar {
      
      .pane-2 {
        margin: 60px 0;
        margin-bottom: 50px;
        padding-right: 0;
        height: 51px;
        text-align: right;
      }

      #views-exposed-form-clone-of-salary-survey-results-panel-pane-1,
      #views-exposed-form-salary-survey-results-2017-fixed-panel-pane-2,
      #views-exposed-form-salary-survey-results-2018-panel-pane-2 {
        margin-top: calc(160px);
      }

      .pane-views {
        background-color: $brand-neutral-light;
        form {
          padding-top: 20px;
          .views-exposed-widget {
            width: 100%;
            margin-bottom: 20px;
            padding-top: 0;
            select.form-control {
              border-radius: 6px;
              border: 1px solid $brand-neutral-light-mid;
            }
          }

          #edit-field-ss-responsibilities-value-wrapper {
            border-top: 1px solid $brand-neutral-light-mid;
            padding-top: 15px;
            margin-top: 15px;
            label {
              padding-top: 0;
              font-size: 18px;
              text-transform: uppercase;
              font-weight: normal;
            }
            div.form-type-bef-link {
              display: inline-block;
              a {
                display: inline-block;
                padding: 15px;
                color: #4e4e4e;
                line-height: 15px;
                margin-bottom: 4px;
                border: 1px solid $brand-neutral-light-mid;
                border-radius: 5px;
                background-color: #fff;
                text-decoration: none;
                &:hover {
                  text-decoration: none;
                  color: #fff;
                  background-color: $brand-secondary-accent;
                }
                &.active {
                  text-decoration: none;
                  color: #fff;
                  background-color: $brand-secondary-accent;
                }
              }
            }
          }

          #edit-field-ss-salary-integer-value-1-wrapper {
            border-top: 1px solid $brand-neutral-light-mid;
            padding-top: 15px;
            margin-top: 15px;
            label {
              padding-top: 0;
              font-size: 18px;
              text-transform: uppercase;
              font-weight: normal;
            }
          }

          #edit-field-ss-hourly-wage-integer-value-wrapper {
            border-top: 1px solid $brand-neutral-light-mid;
            padding-top: 15px;
            margin-top: 15px;
            label {
              padding-top: 0;
              font-size: 18px;
              text-transform: uppercase;
              font-weight: normal;
            }
          }

          #edit-field-ss-salary-integer-value-1-wrapper {
            .views-widget {
              label {
                text-transform: none;
                font-size: 14px;
              }
            }
          }

          #edit-field-ss-hourly-wage-integer-value-wrapper {
            .views-widget {
              label {
                text-transform: none;
                font-size: 14px;
              }
            }
          }

          .views-widget-filter-field_ss_hourly_wage_integer_value,
          .views-widget-filter-field_ss_salary_integer_value_1 {
            .views-widget {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              .form-item {                
                input {
                  width: 100px;
                }
              }
              .form-item-field-ss-hourly-wage-integer-value-max,
              .form-item-field-ss-salary-integer-value-1-max {                
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                label {
                  margin: 0 12px;
                  line-height: 36px;
                }
                input {
                  width: 100px;
                }
              }
            }
            .description {
              font-size: 12px;
              font-style: italic;
            }
          }
        }
      }
    }
    .radix-layouts-content {
      h2.pane-title {
        margin: 60px 0;
        margin-bottom: 50px;
        @media screen and (max-width: 992px) {
          text-align: center;
          margin: 20px 0;
        }
      }
      .mobileshow {
        text-align: center;
        margin-bottom: 20px;
      }
      
      .view-salary-survey-results,
      .view-salary-survey-results-2016,
      .view-salary-survey-results-2017,
      .view-salary-survey-results-2017-fixed,
      .view-salary-survey-results-2018,
      .view-salary-survey-results-2019,
      .view-salary-survey-results-2020,
      .view-salary-survey-results-2021,
      .view-salary-survey-results-2022,
      .view-salary-survey-results-2023,
      .view-salary-survey-results-2024 {
        .view-content {
          > .views-row {
            margin-bottom: 12px;
            .border {        
              padding: 20px;
              border-radius: 10px;
              background-color: #fff;
              box-shadow: 0px 0px 15px 10px rgba(0,0,0,.05);
              color: $brand-neutral-medium;
              .flex {
                justify-content: space-between;
                align-items: center;
                align-content: center;
                flex-direction: row;
                @media screen and (max-width: 992px) {
                  flex-direction: column;
                }
                .first {                
                  width: 55%;
                  .title {
                    padding-bottom: 5px;
                    a {
                      font-weight: bold;
                    }
                  }
                  @media screen and (max-width: 992px) {
                    width: 100%;
                  }
                }
                .second {                  
                  width: calc(30% - 100px);
                  margin-right: 100px;
                  > div {
                    background-image: url('../images/job_icons/corp.svg');
                    background-repeat: no-repeat;
                    background-position: left center;
                    padding-left: 26px;
                  }
                  @media screen and (max-width: 992px) {
                    display: none;
                  }
                }
                .third {
                  width: 15%;            
                  > div {
                    background-image: url('../images/icon/dollar_sign.svg');
                    background-repeat: no-repeat;
                    background-position: left center;
                    padding-left: 26px;
                  }
                  @media screen and (max-width: 992px) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


// individual salary results
body.page-salary-results.panel-layout-radix_boxton {
  #content {
    .container-fluid {
      max-width: 100%;
      width: 100%;
      padding: unset;
      .col-md-12.radix-layouts-content {
        .pane-custom.pane-1 {
          background-color: $brand-neutral-light;
          padding-bottom: 20px;
          @media screen and (max-width: 767px) {
            padding-bottom: 10px;
          }
          .flex {
            max-width: 1140px;
            width: 100%;
            margin: auto;
            justify-content: space-between;
            flex-wrap: wrap;            
            .first {
              @media screen and (max-width: 767px) {
                text-align: center;
              }
            }
            .second {
              padding-top: 60px;
              @media screen and (max-width: 767px) {
                text-align: center;
                padding-top: 20px;
                padding-bottom: 30px;
              }
            }
          }
        }
        .view-salary-survey-single-result {
          background-color: #fff;
          .view-content {
            .views-row {
              .node-salary-survey {
                max-width: 1140px;
                width: 100%;
                margin: auto;
                .content {
                  .result_section {
                    border-bottom: 1px solid $brand-neutral-light-mid;
                    padding-bottom: 40px;
                    margin-bottom: 30px;
                    &:last-child {
                      border-bottom: unset;
                    }
                    h3 {
                      padding: unset;
                      margin: unset;
                      padding-top: 10px;
                      padding-bottom: 30px;;
                      font-family: $font-family-sans-serif;
                      font-size: 18px;
                      text-transform: uppercase;
                      font-weight: normal;
                      @media screen and (max-width: 767px) {
                        font-size: 18px;
                        padding: 5px 0;
                      }
                    }
                    .flex {
                      justify-content: space-between;
                      flex-direction: row;
                      flex-wrap: wrap;
                      @media screen and (max-width: 768px) {
                        flex-wrap: wrap;
                      }
                      > div {
                        
                      }
                      .one {
                        max-width: 170px;
                        width: 100%;                        
                        @media screen and (max-width:399px) {
                          max-width: 100%;
                        }
                        &.grow {
                          @media screen and (max-width:600px) {
                            max-width: 100%;
                            width: 100%;
                          }
                        }
                      }
                      
                      .two {}
                      .three {
                        
                        max-width: calc(170px * 3);
                        width: 100%;
                      }
                    }
                    .field {
                      .field-items {
                        .field-item {
                          font-size: 24px;
                          padding: 10px 0 15px 0;
                          @media screen and (max-width: 767px) {
                            font-size: 14px;
                            padding: 5px 0 5px 0;
                          }
                        }
                      }
                    }                    
                    .field-name-field-ss-grades-served,
                    .field-name-field-ss-benefits,
                    .field-name-field-ss-responsibilities {
                      .field-items {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .field-item {
                          display: inline-block;
                          padding: unset;
                          &:after {
                            content: ", ";
                            padding-right: 5px;
                          }
                          &:last-child:after {
                            content: "";
                            padding-right: unset;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
body.page-user-edit {
  #main-wrapper {
    #main {}
  }
  background-color: #fff;
  #page-header {
    .page-header {
      margin: 20px 0px;
      padding: 0 0;
      @media screen and (min-width: 1024px) {
        padding: 0 80px;
      }
      h1.title {
        font-size: 54px;
        line-height:63px;
        margin: 40px 0;
      }
    }
  }
  #user-profile-form {
    max-width: 1140px;
    margin: auto;
    width: 100%;
    padding: 0 15px;
    .field-group-tab-wrapper {
      .vertical-tabs {
        margin: 0;
        border: unset;
        border-radius: unset;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media screen and (min-width: 1024px) {
          flex-direction: row;
          justify-content: space-between;
        }
        
        &.clearfix {
          &:before,
          &:after {
            content: unset;
            display: unset;
          }
        }



        ul.vertical-tabs-list {
          border: unset;
          border-top: unset;
          float: none;
          margin: unset;
          order: 1;
          margin-bottom: 20px;
          max-width: 750px;
          width: 100%;
          border-left: 1px solid $brand-neutral-light-mid;
          height: 100%;
          
          @media screen and (min-width: 1024px) {
            max-width: 260px;
            order: 2;
            margin-bottom: 0;
            margin-left: 40px;
          }

          li {
            border: unset;
            border-radius: unset;
            padding-left: 3px;
            &:first-child {
              margin-bottom: 10px;
            }
            &:hover {
              background-color: $brand-primary;
              a {
                padding-left: 12px;
                strong {
                  color: $brand-secondary-accent;
                }
              }
            }
            
            
            
            &.selected {
              border: unset;
              border-radius: unset;
              border-left: 3px solid $brand-primary;
              padding-left: 0;
              background-color: $brand-neutral-light;
              a {
                strong {
                  color: $brand-secondary-accent;
                }
              }
            }


            a {
              text-transform: uppercase;
              font-family: $font-family-sans-serif;
              color: $brand-neutral-mid;
              padding: 15px;
              &:hover {
                border-radius: unset;
                border-left: 3px solid $brand-primary;
                
              }
              strong {
                color: $brand-neutral-mid;
              }
            }
          }
        }
        div.vertical-tabs-panes {
          padding: unset;
          order: 2;
          @media screen and (min-width: 1024px) {
            order: 1;
          }
          fieldset.vertical-tabs-pane {
            padding: unset;  
            
            max-width: 750px;
            width: 100%;
            box-shadow: unset;

            .panel-body {
              padding: unset;
              .form-item {
                input {
                  max-width: 750px;
                  width: 100%;
                  box-sizing: border-box;
                  padding: 15px;
                  line-height: 15px;
                  height: auto;
                }
              }
              .confirm-parent,
              .password-parent {
                width: 100%;
              }
              
              fieldset#edit-mimemail {
                border: unset;
                margin-top: 40px;
                box-shadow: unset;
                &.panel {
                  box-shadow: unset;
                }
                legend {
                  border: unset; 
                  padding: unset;
                  background-color: unset;
                  display: none;
                  visibility: hidden;
                  clip: 0;
                  .panel-title.fieldset-legend {
                    background-image:  unset;
                    padding: unset;
                  }
                }
              }

              fieldset#edit-field-user-address-und-0 {
                border: none;
                legend {
                  display: block;
                  visibility: visible;
                  border : unset;
                  background: unset;
                  padding-left: 0;
                  font-weight: bold;
                  font-size: 16px;
                  .panel-title {
                    font-size: 16px;
                    color: $brand-neutral-medium;
                  }
                }
              }

              fieldset.panel,
              fieldset#edit-group_user_edit_required.panel,
              fieldset#edit-mimemail.panel {
                
                box-shadow: unset;
                
              }
            }
          }
        }
      }
    }
  }
}
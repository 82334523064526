// Styles for fields.
// --------------------------------------------------

.btn{
	padding: 12px 24px;
	border-radius: 6px;
	color: $brand-neutral-dark;
	font-weight: 700;
	text-decoration: none;
	font-family: "proxima-nova";
		a{
				color: $brand-neutral-dark;
				text-decoration: none;
		}
}

.btn.primary{
	background: $brand-primary;
	border:solid 2px $brand-primary;

	&:hover{
		background: #F0AF3C;
		border: solid 2px #F0AF3C;
	}
}

.btn.secondary{
	border: solid 2px $brand-primary;

	&:hover{
		border: solid 2px #F0AF3C;
	}
}

.btn.teriary{
	border:solid 2px $brand-neutral-dark;
	&:hover{
		background: $brand-neutral-dark;
		color:#fff;
	}
}

.btn.quaternary{
	background: #fff;
	border:solid 2px #fff;

	&:hover{
		background: #DBD8D7;
		border:solid 2px #DBD8D7;
	}
}
// Styles for individual pages
// --------------------------------------------------
.pane-methodology-overview-panel-pane-1 {
    margin: 0 20px;
}

.facetapi-facetapi-checkbox-links {
    font-size: 14px;
}

.pane-facetapi {
    padding: 0;
    margin: 0;
}

#welcome-dashboard {
    margin-top: 20px;
    .fa {
        text-align: center;
        display: inline-block;
    }
    .panel-panel {}
    ul {
        padding: 0 18px 0;
    }
    a:hover,
    a:focus {
        color: #2a6496;
        text-decoration: none;
    }
}
/*.jobs-newsletter-signup.panel-pane {
    border: solid 1px #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    .form-required {
        display: none;
    }
    .label {
        display: none;
    }
    p {
        margin: 0;
    }
}
*/

.welcome-block {
    border: 1px solid #d0d4d9;
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow: hidden;
    margin-bottom: 30px;
}

.welcome-block-header {
    background: #f5f3f4;
    padding: 10px 25px;
}

.welcome-block-text {
    margin: 25px;
    ul {
        padding: 0 35px 0;
    }
    li {
        font-size: 14px;
        color: rgba(71, 82, 93, 0.9);
    }
    .btn-link {
        width: 100%;
        margin-top: 17px;
    }
}

.view-my-jobs .views-table {
    border: 1px solid #d0d4d9;
    border-radius: 6px;
}

#welcome-dashboard .pane-simplenews-93 {
    margin: 0 15px 30px;
    border: 1px solid #d0d4d9;
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
    h2 {
        background: #f5f3f4;
        padding: 22px 25px;
        font-size: 20px;
        margin: 0;
    }
    .pane-content {
        margin: 25px;
    }
    .form-submit.btn {
        width: 100%;
        margin-top: 21px;
    }
}

.page-user .tabs {
    display: none;
}

.simplenews-subscribe .form-required {
    display: none;
}

.pane-custom.pane-2.post-job {
    margin: 20px 0 10px;
}

.search-widget {
    .views-exposed-form {
        position: relative;
    }
    .views-exposed-widget.views-submit-button {
        // position: absolute;
        // top: 0;
        // right: 0;
    }
}

.job-board-newsletter-signup {
    .form-item-mail.form-group {
        label {
            display: none;
        }
    }
}
/* INSITUTE page */

.experiment h2 {
    color: #11508e;
}

.learn h2 {
    color: #eda900;
}

.improvise h2 {
    color: #f6921e;
}

.master-classes h2 {
    color: #11508e;
}

.cohorts h2 {
    color: #935c25;
}

.showcases h2 {
    color: #701293;
}

.grow h2 {
    color: #269b98;
}

.go-deep h2 {
    color: #f45c21;
}

.pane-bundle-banner-image-centered-text.panel-pane {
    padding: 0;
    img {
        width: 100%;
    }
}

.field-name-field-banner-center-headline {
    margin-top: 65px;
    margin-bottom: 36px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 61px;
    line-height: 65px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bold {
        color: #f7b955;
        font-weight: 700;
    }
}

.field-name-field-banner-center-text {
    display: block;
    width: 100%;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #fff;
    font-weight: 400;
    position: absolute;
    top: 45%;
    text-align: center;
    padding: 0 20%;
    font-size: 24px;
    line-height: 30px;
}

#institute .pane-bundle-icon-text {
    margin-bottom: 130px;
    margin-top: 100px;
    .field-name-field-icon-text-content {
        color: #555;
        font-size: 18px;
        line-height: 23px;
    }
    h2 {
        font-size: 26px;
        margin: 30px 0 23px;
    }
    .field-name-field-icon-text-image img {
        height: auto;
    }
}

.field-name-field-2col-img-text-image {
    width: 60%;
    margin-right: 34px;
    float: left;
    img {
        width: 95%;
        height: auto;
    }
}

.field-name-field-2col-img-text-text {
    h3 {
        margin-bottom: 22px;
        font-family: 'Open Sans', sans-serif;
        color: #44546a;
        font-size: 33px;
        line-height: 32px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-top: 20px;
        font-weight: 700;
    }
    p {
        margin-bottom: 10px;
        color: #555;
        font-size: 18px;
        line-height: 23px;
    }
    .button-yellow {
        margin-top: 17px;
        padding: 9px 15px;
        border-radius: 10px;
        background-color: #f7b955;
        -webkit-transition: background-color 350ms ease;
        transition: background-color 350ms ease;
        font-family: 'Open Sans', sans-serif;
        color: #3b3a3a;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        &:hover {
            background-color: #44546a;
            color: #fff;
        }
    }
}

@media (max-width: 991px) {
    .field-name-field-banner-center-headline {
        font-size: 51px;
        line-height: 55px;
    }
    .field-name-field-banner-center-text {
        padding: 0;
    }
    #institute .pane-bundle-icon-text {
        margin-top: 0;
        margin-bottom: 80px;
    }
    .pane-bundle-banner-image-centered-text.panel-pane {
        margin-bottom: 60px;
    }
    .field-name-field-2col-img-text-text h3 {
        display: inline-block;
    }
}

@media (max-width: 710px) {
    .pane-bundle-banner-image-centered-text.panel-pane img {
        height: 350px;
    }
    .field-name-field-banner-center-headline {
        padding: 0 21px;
        margin-top: 27px;
    }
    .field-name-field-banner-center-text {
        padding: 21px;
        top: 136px;
        font-size: 21px;
    }
    .field-name-field-2col-img-text-image {
        width: 100%;
        margin-right: 0;
        float: none;
    }
}

@media (max-width: 495px) {
    .field-name-field-banner-center-headline {
        margin-top: 14px;
        font-size: 30px;
        line-height: 35px;
    }
    .field-name-field-banner-center-text {
        top: 70px;
        font-size: 19px;
    }
}

@media (max-width: 350px) {
    .field-name-field-banner-center-headline {
        position: relative;
        background: #4c3b3e;
        margin: 0;
        margin-top: -2px;
        padding-top: 11px;
    }
    .field-name-field-banner-center-text {
        position: relative;
        background: #4c3b3e;
        top: 0;
        margin: 0;
    }
    .pane-bundle-banner-image-centered-text.panel-pane img {
        height: auto
    }
}
.banner-slide {
  display: flex;
  position: relative;
  padding: 0;
}
.banner-slide__image {
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}
.banner-slide__content {
  color: #fff;
  z-index: 2;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ($grid-gutter-width *  4.25) $grid-gutter-width;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: rgba($gray-dark, 0.75);
  text-align: center;
}
.banner-slide__eyebrow {
  text-transform: uppercase;
  font-size: 2rem;
  max-width: 70%;
}
.banner-slide__heading {
  color: inherit;
  font-size: 3.5rem;
  line-height: 1;
  margin-top: 0;
}
.banner-slide__cta {
  margin-top: $grid-gutter-width;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  a {
    @extend .btn;
    @extend .btn-primary;
    font-size: 2rem;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: $screen-lg-min) {
  .banner-slide {
    padding: ($grid-gutter-width * 1.5) 0;
  }
  .banner-slide__image {
    position: relative;
    width: 50%;
    flex-grow: 1;
    height: auto;
  }
  .banner-slide__content {
    position: relative;
    z-index: 1;
    width: 50%;
    padding: ($grid-gutter-width * 2) $grid-gutter-width;
    background-color: #fff;
    color: #333;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
  }
  .banner-slide__heading {
    font-size: 54px;
  }
  .banner-slide__cta {
    a {
      background: transparent;
      border: 2px solid darken($brand-primary, 10%);
      font-size: $font-size-base;
      &:hover {
        background: transparent;
        color: #333;
        border: 2px solid $brand-primary;
      }
    }
  }
}
.banner-content {
  display: flex;
  flex-direction: column;
  @media (min-width: $screen-lg-min) {
    flex-direction: row;
  }
}

.banner-left,
.banner-right {
  @extend .col-md-6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.field-name-field-banner-text-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.field-name-field-banner-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: $grid-gutter-width;
  h3 {
    max-width: auto;
    margin: 0;
    padding-top: 0 !important;
    padding-right: 2rem;
  }

  @media (min-width: $screen-lg-min) {
    text-align: left;
    margin-bottom: 0;
  }

}

body.node-type-showcase-recruit {

  // #main-wrapper {
  //   #main {
  //     .container {
  //       #content {
  //         .region-content {
  //           .node-showcase-recruit {
  //             .content {
  //               border: 1px solid red;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }


  #main-wrapper {
    background-color: #fff;
    .container {
      padding: 0;
      margin: 0;      
      margin: auto;
      width: 100%;
      max-width: 691px;
      padding: 0 5%;
      
      @media (min-width: 768px) {
         max-width: 691px; 
         padding: 0;
         
      }
      @media (min-width: 992px) {
        max-width: 936px;         
        
      }
      @media (min-width: 1200px) {
        max-width: 1140px;      
        
      }   
    }
    #content {
      
      max-width: 1300px;
      width: 100%;
      margin: auto;
      .page-title {
        margin-top: 30px;
        margin-bottom: 30px;
        .page-title-logo {
          max-width: 100px;
          width: 100%;
          height: auto;
        }
        h1 {
          margin: 0;
          margin-top: 30px;
          margin-bottom: 10px;
          @media screen and (max-width:480px) {
            font-size: 20px;
          }
        }
      }
      .flex {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 1024px) {
          flex-direction: row;
          justify-content: space-between;
        }
        > .first {                    
          width: 100%;
          @media screen and (min-width: 1024px) {
            max-width: 650px;
          }          
          .story {
            margin: 20px 0;
          }
          .pivotcircle-image {
            img {
              max-width: 500px;
              width: 100%;
              height: auto;
            }
          }
        }
        > .second {          
          width: 100%;
          @media screen and (min-width: 1024px) {
            max-width: 360px;
            padding-left: 20px;
          }
          .details {
            > h3 {
              font-family: "proxima-nova", sans-serif;
              font-size: 16px;          
              font-weight: bold;
              line-height: 1.42857;
            }
            font-size: 24px;
            color: $brand-neutral-mid;
            @media screen and (max-width:480px) {
              font-size: 20px;
            }
          }

          .new-leader {
            border-bottom: 1px solid $brand-neutral-light-mid;
            padding-bottom: 30px;
            margin-bottom: 30px;
            h3 {
              margin: 0;
              margin-bottom: 20px;
              @media screen and (max-width:480px) {
                font-size: 20px;
                margin-top: 30px;
              }
            }
            .new-leader-image {
              max-width: 100px;
              width: 100%;
            }
            .new-leader-name {
              font-weight: bold;
              margin: 10px 0;
            }
            .new-leader-url {
              padding-top: 10px;
              a {
                img {
                  width: 20px;
                  height: auto;
                }
              }
            }
          }
          .video {
            margin-bottom: 20px;
            iframe {              
              width: 100%;
              
              @media screen and (min-width: 1024px) {
                max-width: 360px;
              }
            }
          }
          .opp-statement {
            border-bottom: 1px solid $brand-neutral-light-mid;
            padding-bottom: 30px;
            margin-bottom: 30px;
            a {
              border: 2px solid $brand-warning;
              background-color: #fff;
              color: $brand-neutral-dark;
              background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/file-regular.svg');
              background-size: 12px;
              background-position: right 15px center;
              background-repeat: no-repeat;
              padding-right: 40px;
              
              &:hover {
                background-color: $brand-warning;
              }
            }
          }
        }
      }
    }
  }
}
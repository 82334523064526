// Styles for User pages
// --------------------------------------------------
.pane-user-login {
    margin-top: 22px;
    margin-bottom: 50px;
    .item-list ul {
        display: none;
    }
    .form-required {
        display: none;
    }
}

.pane-user-login .form-item {
    margin-bottom: 30px;
}

.mollom-privacy.description {
    margin: 15px 0;
}

@media (max-width: 766px) {
    div.vertical-tabs {
        margin: 1em 0 1em 0em;
    }
}

#admin-menu-menu li:first-child{
    display: none;
}

.role-administrator, 
.role-job-board-manager,
.role-internal-application-manager,
.role-website-manager{

    #admin-menu-menu li:first-child{
    display: block;
    }
}
ul.pagination.pager {
  li.pager-item {
    a {
      border: unset;
      color: #fff;
      color: $brand-primary-accent;
      &:hover {
        background-color: $brand-secondary-accent;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
  li.pager-current.active {
    border-radius: 4px;
    border: unset;
    span {
      border: 1px solid $brand-primary-accent;
      background-color: $brand-primary-accent;
    }
  }
  li.pager-first,
  li.pager-previous,
  li.pager-last,
  li.pager-next {
    a {
      border: unset;
      border: 1px solid $brand-primary-accent;
      border-radius: 4px;
      color: #fff;
      color: $brand-primary-accent;
      &:hover {
        background-color: $brand-secondary-accent;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
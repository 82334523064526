.job-card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  .job-card__logo {
    margin-right: $grid-gutter-width / 2;
    display: flex;
  }
  .job-card__content {
    display: flex;
    flex-direction: column;
  }
  .job-card__heading {
    font-family: "proxima-nova";
    a {
      text-decoration: none;
    }
  }
}

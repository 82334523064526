// Styles for Forms
// --------------------------------------------------


//Mailchimp signup
#mc_embed_signup{
	background: transparent !important;
	margin-top:20px;

	form{
		padding: 0 !important;
	}

	label{
		display: none !important;
	}

	h2{
		margin:0 !important;
		font-size: 16px !important;
	}

	.button{
		background: #F4A10C !important;
	}
	input.email{
		color:#000 !important;
	}
	.mc-field-group{
	margin-top: -10px !important;
	}
}
body.node-type-second-level-landing {
  #main-wrapper {
    background-color: $white;
    .container {
      padding: 0;
      margin: 0;      
      margin: auto;
      width: 100%;
      max-width: 691px;
      padding: 0 5%;
      
      @media (min-width: 768px) {
         max-width: 691px; 
         padding: 0;
         
      }
      @media (min-width: 992px) {
        max-width: 936px;         
        
      }
      @media (min-width: 1200px) {
        max-width: 1140px;      
        
      } 
      #page-header {
        .page-header {
          margin: 0;
          padding: 0;
          h1.title {
            margin: 0;
            margin-top: 78px;
            font-family: $font-family-serif;
            font-size: 45px;
            line-height: 51px;            
            margin-bottom: 50px;
            max-width: 750px;
            width: 100%;
          }
        }
      }
  
    }
    #content {
      .node-second-level-landing {
        max-width: 1140px;
        width: 100%;
        margin: auto;
        .flex {
          width: 100%;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 769px) {
            flex-direction: row;
            justify-content: space-between;
          }
          > .first {
            max-width: 750px;
            width: 100%;
            h1.subtitle {
              font-size: 45px;
              line-height: 51px;
              margin-bottom: 50px;
            }
            .field-name-body {
              margin-top: 20px;
              font-size: 18px;
              line-height: 33px;
              h2 {
                margin: 50px 0;
              }
              h3 {
                margin: 20px 0;
              }
              p {
                line-height: 33px;
              }
            }
          }
          > .second {
            max-width: 269px;
            width: 100%;
            .sidebar-items {
              text-align: right;
              a {
                display: inline-block;
                padding: 12px 24px;
                border-radius: 6px;
                font-weight: 700;
                font-family: $font-family-sans-serif;
                text-decoration: none;
              }
              .field-name-field-primary-link {
                display: block;
                margin-bottom: 10px;
                a {                 
                  background: $brand-primary;
                  color: #fff;
                  border: solid 2px $brand-primary;
                  &:hover {
                    background-color: $brand-primary-hover;
                    
                    border: 2px solid $brand-primary-hover;
                  }
                }
              }
              .field-name-field-secondary-link {
                margin-bottom: 10px;
                display: block;
                a {                  
                  background: transparent;
                  border: 2px solid #b47c19;
                  color: $brand-neutral-dark;
                  &:hover {
                    color: $brand-primary-hover;
                    border: 2px solid $brand-primary-hover;
                  }
                }
              }
              .field-name-field-many-links {
                a {
                  padding: 0;
                  border-radius: unset;
                  font-weight: normal;
                  color: $brand-neutral-mid;
                  text-decoration: underline;
                  margin-bottom: 3px;
                  &:hover {
                    color: $brand-primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
//
// CSS Helpers
// --------------------------------------------------


.mobileshow {
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
}

.smallhide {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.smallshow {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
body.page-toboggan-denied,
body.page-register,
body.page-user-password,
body.custom-login-page,
body.page-user-login {
  background-color: #fff;
  .login_page_header {
    text-align: center;
    padding: 40px 0;
    @media screen and (max-width: 480px) {
      padding: 15px 0;
    }
    h1.title {
      font-size: 45px;
      line-height: 50px;
      margin-top: 10px;
    }
  }


  .pane-user-login,
  .pane-custom.pane-2 {
    max-width: 370px;
    width: 100%;
    margin: auto;
  }

  .pane-user-login {
    .form-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      #edit-submit {
        //        
      }
    }
  }

  .pane-custom.pane-2 {
    margin-top: 40px;
    font-size: 12px;
    .pane-content {
      text-align: center;
      h4 {
        display: inline-block;
        font-size: 12px;
        padding-right: 5px;
      }
    }

  }

  #login-form {
    form {
      max-width: 370px;
      width: 100%;
      margin: auto;
      .form-item {
        .help-block {
          font-size: 12px;
          padding: 10px;
          color: $text-color;
        }
      }
      .login-forgot {
        text-align: center;
      }
      .form-actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        #edit-submit {
          margin: auto;
        }
      }
    }
  }

  #user-pass {
    max-width: 370px;
    width: 100%;
    margin: auto;
    .form-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      #edit-submit {
        margin: auto;
      }
    }
  }

  .custom_message {
    max-width: 370px;
    width: 100%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 12px;
  }

}

body.page-toboggan-denied,
body.page-register {
  #content {
    padding: 0 0;
    .pane-custom.pane-1 {
      margin-bottom: 20px;
      .pane-content {
        text-align: center;
      }
    }
    max-width: 560px;
    width: 100%;
    margin: auto;
    form {
      // .field-name-field-user-newsletter {
      //   display: none;
      // }
      .form-item {
        margin-bottom: 30px;
        .help-block {
          font-size: 12px;
          padding: 10px;
          color: $text-color;
        }
      }
      fieldset.form-wrapper,
      fieldset.collapsible {
        border: unset;
        background-color: unset;
        padding: 10px 0;
        box-shadow: unset;
        legend {
          border: unset;
          background-color: unset;
          padding: 10px 0;
          box-shadow: unset;
          background-image: unset;
          a.fieldset-title {
            background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/optional_downarrow.svg');
            background-position: right center;
            background-repeat: no-repeat;
            padding-right: 20px;
            background-size: 15px auto;
          }
        }
        .panel-body {
          padding: 0;
        }
      }
    }
  }
  
  fieldset.captcha,
  #edit-terms-of-use {
    legend,
    p.help-block {
      display: none;
    }
  }
  .form-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 100px;
  }



}

html.js {
  body.page-toboggan-denied,
  body.page-register {
    fieldset.collapsible .fieldset-legend,
    fieldset.collapsed .fieldset-legend {
      background-image: unset;
      padding-left: 0;
    }
  }
}
// Styles for main layout
// --------------------------------------------------
#header {
  background: $brand-primary-accent;
}

#header .container {
  background: $brand-primary-accent;
  border: none;
}

.navbar-brand > img {
  display: block;
  width: 181px;
}

.container {
  border: none;
}

.navbar {
  margin-bottom: 15px;
}

#content {
  padding: 0 80px;
}

.page-manager-page #content {
  padding: 0;
}

.page-header {
  margin: 21px 50px 0;
  padding-left: 15px;
  border-bottom: 0;
}

.tabs, .nav-tabs, .action-links {
  margin: 20px 0;
}

.page-manager-page h1.title {
  padding-left: 15px;
  padding-right: 15px;
}

#content {
  padding-bottom: 50px;
}

#pre-footer,
#footer {
  background: $brand-primary;
}

#footer {
  margin: 0;
  margin-bottom: 0;
  color: $brand-neutral-dark;
}

#pre-footer p {
  color: $brand-neutral-dark;
  font-size: 24px;
  clear: both;
}

#pre-footer {
  border-bottom: solid 1px $brand-neutral-dark;
  padding-bottom: 35px;
  margin-bottom: 40px;
  padding-top: 36px;
  width: 100%;
}

.contactus-buttons {
  a.quaternary {
    margin-right: 12px;
  }
  .phone {
    background: url(../images/icon/icon_phone.svg);
    background-size: 13px 18px;
    background-repeat: no-repeat;
    background-position: 18px center;
    width: 170px;
    padding-left: 43px;
  }
  span.btn.teriary.phone:hover {
    background-color: transparent !important;
    background: url(../images/icon/icon_phone.svg);
    background-size: 13px 18px;
    background-repeat: no-repeat;
    background-position: 18px center;
    cursor: auto;
    color: $btn-default-color;

  }
  a.phone:hover {
    background: url(../images/icon/icon-phone-white.svg) $brand-neutral-dark;
    background-repeat: no-repeat;
    background-position: 18px center;
  }
}



.pane-fpid-6 {
  margin-top: 50px;
}

.footer-bottom {
  background: #F0AF3C;
  padding: 16px 0 8px;
  color: $brand-neutral-dark;
  a {
    color: $brand-neutral-dark;
    text-decoration: none;
    &:hover {
      color: $brand-primary-accent;
    }
  }
  .back-top {
    background: url(../images/icon/icon-backtop.svg);
    background-size: 27px;
    background-repeat: no-repeat;
    background-position: right;
    padding-right: 35px;
    height: 27px;
    display: block;
    padding-top: 3px;
  }
  @media screen and (max-width:480px) {
    .copyright {
      text-align: center;
    }
  }
}

// Make display suite columns use Bootstrap columns.
.ds-2col > .group-left,
.ds-2col > .group-right {
  @media (max-width: $screen-md-max) {
    float: initial;
    width: initial;
  }
  @extend .col-md-6;
}



footer {
  .container {
    #pre-footer {
      .contactus-buttons {
        .teriary {
          @media screen and (max-width:480px) {
            margin-top: 10px;
          }          
        }
      }
    }
    #footer-columns {
      margin-left: -15px;
      margin-bottom: 54px;
      clear: both;
      @media screen and (max-width:991px) {
        text-align: center;
        margin-left: unset;
        width: 90%;
      }
      .block-menu-block {
        h2 {
          font-family: "proxima-nova";
          font-size: 18px;
          color: $brand-neutral-dark;
          letter-spacing: 1px;
          line-height: 21px;
          margin-bottom: 12px;
          text-transform: uppercase;
          margin: 0 0 15px 0;
          font-weight: 500;
        }
        li a {
          font-size: 16px;
          color: $brand-neutral-dark;
          text-decoration: none;
          margin: 10px 0;
          &:hover {
            color: $brand-primary-accent;
          }
        }
      }
      .footerfifth {
        float: right;
        text-align: right;
        color: $brand-neutral-dark;
        
        .phone {
          background: url(../images/icon/icon_phone.svg);
          background-size: 13px 18px;
          background-repeat: no-repeat;
          background-position: left;
          width: 122px;
          float: right;
        }
        @media screen and (max-width:991px) {
          float: unset;
          text-align: left;
          padding: unset;
          margin: unset;
          width: 100%;
          text-align: center;
          .phone {
            float: unset;
            text-align: center;
            margin: auto;
            float: unset;
          }
        }
      }
      .footer-logo {
        width: 75px;
        margin-bottom: 16px;
      }

      p {
        clear: both;
      }
      .social-icon {
        padding-left: 10px;
        padding-right:0;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
.page-node-add-job {
  #content {
    .container-fluid {
      max-width: 1140px;
      width: 100%;
      margin: auto;
      .flex {
        justify-content: space-between;
        .first {
          max-width: 360px;
          width: 100%;
          padding-right: 20px;
          h1.title {
            font-size: 54px;
            margin: 0;
            margin-bottom: 40px;
          }
        }
        .second {
          .panel {
            box-shadow: unset;
          }
          max-width: 660px;
          width: 100%;
          #edit-field-field-job-app-instructions-und-0-format,
          #edit-field-job-original-posting-und-0-format {
            display: none;
          }
          #field-field-job-app-instructions-add-more-wrapper {
            margin: 40px 0;
          }
          #edit-field-job-image-und-0-ajax-wrapper {
            .help-block {
              display: none;
            }
          }
          .help-block {
            color: $text-color;
          }

          #edit-field-job-school-classify-ue {
            #edit-field-job-school-classify-ue-und {
              div {
                margin-bottom: 15px;
              }
            }
            
          }

          .form-submit {
            border: 4px solid $brand-primary;
          }
          .form-submit:hover {
            border: 4px solid $brand-primary-hover;
          }

          .file-widget,
          .image-widget-data {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px 0;
            align-content: center;
            align-items: center;
            @media screen and (max-width: 480px) {
              display: block;
            }
          }

          #edit-field-job-posted-documents-und--2-table {
            .file-widget {
              display: block;
              .form-type-textfield {
                label {
                  display: block;
                }
              }
            }
          }

          .file-widget.form-managed-file.clearfix:before,
          .file-widget.form-managed-file.clearfix:after {
            content: unset;
            display: flex;
          }

          #edit-field-job-posted-documents {
            margin: 0;
            margin: 20px 0;
            color: $text-color;            
            fieldset.form-wrapper {
              background-color: unset;
              border: unset;
              padding-left: 0;
              padding-right: 0;
              legend {
                background-color: unset;
                border: unset;
                padding-left: 0;
                padding-right: 0;
                .fieldset-legend {
                  text-transform: uppercase;
                  font-size: 18px;
                  color: $text-color;
                  padding-left: 0;
                  padding-right: 0;
                }
              }
              .panel-body {
                padding-left: 0;
                padding-right: 0;
              }
              p.help-block {
                color: $text-color;
                small {
                  font-size: 16px;
                }
              }
            }
            .form-type-managed-file {              
              label {
                font-size: 16px;
              }
              input {
                
              }

            }
          }
          fieldset.date-combo,
          fieldset.group-job-requestor-info,
          fieldset.group-job-admin {
            margin: 0;
            margin: 20px 0;
            color: $text-color;   
            background-color: unset;
            border: unset;
            padding-left: 0;
            padding-right: 0;
            legend {
              background-color: unset;
              border: unset;
              padding-left: 0;
              padding-right: 0;
              .fieldset-legend {
                text-transform: uppercase;
                font-size: 18px;
                color: $text-color;
                padding-left: 0;
                padding-right: 0;
              }
            }
            .panel-body {
              padding-left: 0;
              padding-right: 0;
            }
          }
          .container-inline-date {
            .help-block {
              display: none;
            }
            .date-padding {
              padding: 0;
            }
          }
          #edit-field-job-deadline-ue {
            legend {
              padding-bottom: 0;
              .panel-title {
                font-size: 16px;
                font-weight: bold;
                text-transform: none;
              }
            }
            .panel-body {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}




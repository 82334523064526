.about-principle__content {
  color: $brand-neutral-mid;
  display: flex;
  flex-direction: column;

  .about-principle__left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: $grid-gutter-width * 2;
  }

  .about-principle__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
  .field-name-title-ds {
    display: flex;
    justify-content: center;
    h3 {
      margin-top: 0;
      line-height: 1;
    }
  }

  .field-name-field-about-principle-title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: $brand-neutral-mid;
  }
  .field-name-field-about-principle-bio {
    text-align: center;
  }
}

.pane-bundle-about-us-principle-bio {
  .pane-title {
    display: none;
  }
}
@media (min-width: $screen-lg-min) {
  .about-principle__content {
    flex-direction: row;
    .about-principle__left {
      flex-basis: 30%;
    }
    .about-principle__right {
      padding-left: $grid-gutter-width;
      align-items: flex-start;
      text-align: left;
      flex-basis: 70%;
    }
    .field-name-field-about-principle-bio {
      text-align: left;
    }
    .field-name-field-about-principle-title {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      color: $brand-neutral-mid;
    }
  }
}

body.node-type-article {
  #main-wrapper {
    background-color: $white;
    .container {
      padding: 0;
      margin: 0;      
      margin: auto;
      width: 100%;
      max-width: 691px;
      padding: 0 5%;
      padding-top: 40px;
      @media (min-width: 768px) {
         max-width: 691px; 
         padding: 0;
         padding-top: 48px;
      }
      @media (min-width: 992px) {
        max-width: 936px;         
        padding-top: 58px;
      }
      @media (min-width: 1200px) {
        max-width: 1140px;      
        padding-top: 78px;   
      } 
      #page-header {
        .page-header {
          margin: 0;
          padding: 0;
          h1.title {
            margin: 0;
            font-family: $font-family-sans-serif;
            font-size: 24px;
            font-weight: normal;
            line-height: 30px;
            color: $brand-neutral-medium;
          }
        }
      }
  
    }
    #content {
      .node-article {
        
        width: 100%;
        margin: auto;
        @media (min-width: 768px) {
          max-width: 720px; 
        }
        @media (min-width: 992px) {
          max-width: 936px;         
        }
        @media (min-width: 1200px) {
          max-width: 1140px;         
        } 
        .flex {
          width: 100%;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 769px) {
            flex-direction: row;
            justify-content: space-between;
          }
          > .first {
            max-width: 750px;
            width: 100%;        
            h1.subtitle {
              font-size: 45px;
              line-height: 51px;
              margin-bottom: 25px;
              @media screen and (max-width: 480px) {
                font-size: 30px;
                line-height: 36px;
              }
            }

            .field-name-field-article-executive-summary {
              margin-bottom: 25px;
              font-size: 18px;
              line-height: 33px;
            }

            .field-name-body {
              font-size: 18px;
              line-height: 33px;
              h2 {
                margin: 50px 0;
              }
              h3 {
                margin: 20px 0;
              }
              p {
                line-height: 33px;
              }
            }
            .authorinfo {              
              max-width: 750px;
              width: 100%;
              .flex {
                width: 100%;
                display: flex;
                flex-direction: column;
                @media screen and (min-width: 769px) {
                  flex-direction: row;
                  justify-content: space-between;
                }
                .first {
                  max-width: 100px;
                  width: 100%;
                  height: auto;
                  margin-right: 20px;
                }
                .second {
                  max-width: 630px;
                  width: 100%;
                  font-size: 16px;
                  h4 {
                    margin-top: 0;
                    font-weight: bold;
                    font-size: 16px;
                  }
                  p {
                    font-family: "Proxima Nova", sans-serif;
                    font-size: 18px;
                    margin-left: 0;
                    line-height: 33px;
                  }

                  .field-name-field-author-name {
                    font-weight: bold;
                    margin-bottom: 5px;
                  }
                }

              }
            }
          }
          > .second {
            max-width: 269px;
            width: 100%;
            @media screen and (max-width: 480px) {
              max-width: 100%;
              margin-top: 20px;
            }
            .sidebar-menu {
              ul.nav {
                border-left: solid 1px #DBD8D7;
              }

              .nav-pills > li > a {
                color: #72716D;
                text-decoration: none;
                text-transform: uppercase;
                padding-left: 25px;
                margin-bottom: 8px;
                letter-spacing: 1.3px;              
                &:first-child {
                }

                &:hover {
                  color: #194D59;
                }

                &:hover, &.active {



                }
                &.active {


                  background: #F2F1F0;
                  border-left: solid 5px #E09B20;
                  padding-left: 20px;

                }
              }
              .nav-pills > li.menu-link-articles > a {


                background: #F2F1F0;
                border-left: solid 5px #E09B20;
                padding-left: 20px;
                color: #194D59;

                &:hover {
                  color: #194D59;
                }

              }
            }
          }
        }
      }
    }
  }
}
body.page-welcome {
  background-color: $brand-neutral-light;
  #page-header {
    .page-header {
      margin: 0;
      padding: 0;
      margin-top: 40px;
      h1.title {
        font-size: 54px;
        line-height: 63px;
        
      }
    }
  }
  #content {
    .container-fluid {
      max-width: 1140px;
      .radix-layouts-content {

        .view-id-my_applications {
          .view-content {
            .views-table {
              border: unset;
              margin-bottom: 0;
              tbody {
                border: unset;
                tr {
                  border: unset;
                  background-color: unset;
                  td {
                    border: unset;
                    border-top: unset;
                  }
                }                
              }
            }
          }
        }

        .panel-pane {
          margin-bottom: 40px;
          h3.pane-title {
            font-size: 18px;
            line-height: 21px;
            text-transform: uppercase;
            font-weight: normal;
            font-family: $font-family-sans-serif;
            letter-spacing: 1px;
            color: $brand-neutral-medium;
          }
          .pane-content {
            padding: 20px;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0px 0px 15px 10px rgba(0,0,0,.05);
            color: $brand-neutral-medium; 
            p {
              color: $brand-neutral-medium;
            }
          }
        }
        .pane-my-jobs {
          
          .pane-content {
            padding: unset;
            border-radius: unset;
            background-color: unset;
            box-shadow: unset;
            color: $brand-neutral-medium; 
            p {
              color: $brand-neutral-medium;
            }
            .view {
              
              .view-header {
                font-size: 18px;
                line-height: 21px;
                text-transform: uppercase;
                font-weight: normal;
                font-family: $font-family-sans-serif;
                letter-spacing: 1px;
                margin-bottom: 12px;
                color: $brand-neutral-medium; 
                
              }
              .view-content {
                padding: 20px;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0px 0px 15px 10px rgba(0,0,0,.05);
                color: $brand-neutral-medium; 
                .views-row {
                  margin-bottom: 5px;
                }
                p {
                  color: $brand-neutral-medium;
                }
              }
            }
          }
        }

        .pane-menu-menu-recruiting-manager-menu,
        .pane-menu-menu-job-manager-menu {
          h4.pane-title {
            font-size: 18px;
            line-height: 21px;
            text-transform: uppercase;
            font-weight: normal;
            font-family: $font-family-sans-serif;
            letter-spacing: 1px;
            margin-bottom: 12px;
            padding: unset;
            color: $brand-neutral-medium;
          }
          .pane-content {
            ul.menu {
              padding-left: 0;
              li {
                a {
                  padding-left: 0;
                }
              }
            }
          }
        }

        .pane-custom.pane-1,
        .pane-custom.pane-2,
        .pane-custom.pane-3,
        .pane-block-18 {
          .pane-content {
            padding: unset;
            border-radius: unset;
            background-color: unset;
            box-shadow: unset;
            .welcome-block {
              border: unset;
              border-radius: unset;
              display: unset;
              .welcome-block-header {
                padding: unset;
                h4 {
                  font-size: 18px;
                  line-height: 21px;
                  text-transform: uppercase;
                  font-weight: normal;
                  font-family: $font-family-sans-serif;
                  letter-spacing: 1px;
                  margin-bottom: 12px;
                  padding: unset;
                  color: $brand-neutral-medium;
                }
              }
              .welcome-block-text {
                margin: unset;
                padding: 20px;
                border-radius: 10px;
                background-color: #fff;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: flex-start;
                align-items: flex-start;
                box-shadow: 0px 0px 15px 10px rgba(0,0,0,.05);
                color: $brand-neutral-medium;
                p {
                  color: $brand-neutral-medium;
                }
                ul {
                  margin: 0px;
                  padding: 0px;
                  list-style: none;
                  list-style-type: none;
                  li {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;
                    list-style-type: none;
                    line-height: 30px;
                    background-size: 17px;
                    background-position: left center;
                    background-repeat: no-repeat;
                    padding-left: 26px;
                    color: $brand-neutral-medium;
                  }
                  li:nth-child(1),
                  li:nth-child(2),
                  li:nth-child(3) { background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/dashboard_blue_checkmark.svg'); }
                  li:nth-child(4) { background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/dashboard_plus_mark.svg'); }
                }
                // a { 
                //   display: block;
                //   width: 170px;
                //   height: auto;
                //   background-image: url('/sites/all/themes/edtechrecruiting/assets/images/icon/post_job_plus_mark.svg');
                //   background-size: 17px;
                //   background-position: left 15px center;
                //   background-repeat: no-repeat;
                //   padding-left: 20px;   
                //   margin-top: 0;
                //   padding: 12px 24px;
                //   border: unset;
                //   border-radius: 6px;
                //   color: #28292d;
                //   font-weight: 700;
                //   text-decoration: none;
                //   font-family: "proxima-nova";
                //   text-align: center;
                //   background-color: $brand-primary;
                //   &:hover {
                //     background-color: $brand-primary-hover;
                //   }
                // }
              }
            }
          }
        }
        .pane-custom.pane-1 {
          .pane-content {
            .welcome-block {
              .welcome-block-text {
                flex-direction: column;
                ul {
                  margin-bottom: 20px;
                }
                a {
                  width: 100%;
                  margin-bottom: 15px;
                  background-image: unset;
                  border: unset;
                }
              }
            }
          }
        }
      }
      .radix-layouts-sidebar {
        .panel-pane {
          .welcome-block {
            border: unset;
            margin: unset;
            padding: unset;
            margin-bottom: 40px;
            .welcome-block-header {
              padding: unset;
              h4 {
                font-size: 18px;
                line-height: 21px;
                text-transform: uppercase;
                font-weight: normal;
                font-family: $font-family-sans-serif;
                letter-spacing: 1px;
                margin-bottom: 12px;
                padding: unset;
                color: $brand-neutral-medium;
              }
            }
            .welcome-block-text {
              margin: unset;
              margin: 10px 0;
              color: $brand-neutral-medium;
              p {
                color: $brand-neutral-medium;
              }
              a {
                display: block;
                max-width: 200px;
                width: 100%;
                border: 2px solid $brand-primary;
                font-family: $font-family-sans-serif;
                font-size: 18px;
                font-weight: bold;
                color: $brand-neutral-dark;
                background-color: #fff;
                &:hover {
                  border: 2px solid $brand-primary-hover;
                }
              }
            }
          }
        }
      }
      .pane-simplenews {
        border: unset;
        padding: 0;
        h2 {
          font-size: 18px;
          line-height: 21px;
          text-transform: uppercase;
          font-weight: normal;
          font-family: $font-family-sans-serif;
          letter-spacing: 1px;
          margin-bottom: 12px;
          padding: unset;
          color: $brand-neutral-medium;
        }
        .pane-content {
          margin: 10px 0;
          p {
            color: $brand-neutral-medium;
          }
          form {
            input#edit-submit {
              display: block;
              max-width: 200px;
              width: 100%;
              border: 2px solid $brand-primary;
              font-family: $font-family-sans-serif;
              font-size: 18px;
              font-weight: bold;
              color: $brand-neutral-dark;
              background-color: #fff;
              &:hover {
                border: 2px solid $brand-primary-hover;
              }
            }
          }

        }
      }
    }
  }
}